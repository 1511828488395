import React from 'react';
import './App.css';
import 'moment/locale/it';
//------------------------- Component Import ----------------------------------
import LayoutGeneral from '../hoc/LayoutGeneral';
import HomePage from './PublicPages/HomePage/HomePage';
import CategoriesPage from './PublicPages/CategoriesPage/CategoriesPage';
import SupplierPage from './PublicPages/SupplierPage/SupplierPage';
import Profile from './PrivatePages/ProfilePage/ProfilePage';
import Contact from './PublicPages/ContactPage/ContactPage';
import SupplierDetailPage from './PublicPages/SupplierDetailPage/SupplierDetailPage';
import ConfirmCartPage from './PrivatePages/ConfirmCartPage/ConfirmCartPage';
import WhoWeAre from './PublicPages/WhoweAre/WhoWeAre';
import WorkWithUs from './PublicPages/WorkWithUs/WorkWithUs';
import HowItWork from './PublicPages/HowItWork/HowItWork';
import Terms from './PublicPages/Terms/Terms';
import Privacy from './PublicPages/Privacy/Privacy';
//------------------------- Auth Pages ----------------------------------------
import Login from './AuthPages/Login/Login';
import Registration from './AuthPages/Registration/Registration';
import EmailConfirmation from './AuthPages/EmailConfirmation/EmailConfirmation';
import ForgotPassword from './AuthPages/ForgotPassword/ForgotPassword';
import ResetPassword from './AuthPages/ResetPassword/ResetPassword';
//--------------------------  Route Import -----------------------------------
import { Redirect, Route, RouteProps, Router as BrowserRouter, Switch, useHistory } from 'react-router-dom';
//----------- Error Page------------
import Page401 from './ErrorPages/Page401';
import Page403 from './ErrorPages/Page403';
import Page404 from './ErrorPages/Page404';
import Page500 from './ErrorPages/Page500';
import EditOrder from './PrivatePages/EditOrder/EditOrder';


// this is kept in sync by actions signIn and signOut
const hasAddress = () => localStorage.getItem('address');
const PublicRoute = (props: RouteProps) => <Route {...props} />;
const PrivateRoute = (props: RouteProps) => hasAddress() ? <Route {...props} /> : <Redirect to={'/'} />;


const App = () => {
  const history = useHistory();

  //------------------------------------ Rendering ----------------------------------------------
  return (
    <div className='App'>
      <BrowserRouter history={history}>
        <Switch>

          <PublicRoute path="/" exact render={() => <LayoutGeneral page={'HomePage'} ><HomePage /></LayoutGeneral>} />
          <PublicRoute path="/contatti" exact render={() => <LayoutGeneral page={'Contatti'} ><Contact /></LayoutGeneral>} />
          <PublicRoute path="/chi-siamo" exact render={() => <LayoutGeneral page={'Chi-Siamo'} ><WhoWeAre /></LayoutGeneral>} />
          <PublicRoute path="/lavora-con-noi" exact render={() => <LayoutGeneral page={'Lavora-Con-Noi'} ><WorkWithUs /></LayoutGeneral>} />
          <PublicRoute path="/come-funziona" exact render={() => <LayoutGeneral page={'Come-Funziona'} ><HowItWork /></LayoutGeneral>} />
          {/*--------------- */}
          <PublicRoute path="/accedi" exact render={() => <LayoutGeneral page={'Accedi'} ><Login /></LayoutGeneral>} />
          <PublicRoute path="/registrati" exact render={() => <LayoutGeneral page={'Registrati'} ><Registration /></LayoutGeneral>} />
          <PublicRoute path="/conferma-email/:token" exact render={() => <LayoutGeneral page={'Conferma-Email'} ><EmailConfirmation /></LayoutGeneral>} />
          <PublicRoute path="/password-dimenticata" exact render={() => <LayoutGeneral page={'Password-Dimenticata'} ><ForgotPassword /></LayoutGeneral>} />
          <PublicRoute path="/resetta-password/:token" exact render={() => <LayoutGeneral page={'Resetta-Password'} ><ResetPassword /></LayoutGeneral>} />
          {/*-------------- Route Address ----------------- */}
          <PrivateRoute path="/categorie" exact render={() => <LayoutGeneral page={'Categorie'}><CategoriesPage /></LayoutGeneral>} />
          <PrivateRoute path="/suppliers/:categoryId" exact render={() => <LayoutGeneral page={'Suppliers'}><SupplierPage /></LayoutGeneral>} />
          <PrivateRoute path="/supplier/:supplierId/:carrello?" exact render={() => <LayoutGeneral page={'Supplier'}><SupplierDetailPage /></LayoutGeneral>} />
          {
            //<PrivateRoute path="/modifica-ordine/:orderId" exact render={() => <LayoutGeneral page={'Modifica-Ordine'}><EditOrder /></LayoutGeneral>} />
          }
          <PrivateRoute path="/profilo" exact render={() => <LayoutGeneral page={'Profilo'}><Profile /></LayoutGeneral>} />
          <PrivateRoute path="/conferma-carrello" exact render={() => <LayoutGeneral page={'ConfirmCarrello'}><ConfirmCartPage /></LayoutGeneral>} />
          <PublicRoute path="/termini-e-condizioni" exact render={() => <LayoutGeneral page={'Termini'} ><Terms /></LayoutGeneral>} />
          <PublicRoute path="/privacy-policy" exact render={() => <LayoutGeneral page={'Privacy'} ><Privacy /></LayoutGeneral>} />
          <PublicRoute path="/403" exact component={Page403} />
          <PublicRoute path="/401" exact component={Page401} />
          <PublicRoute path="/500" exact component={Page500} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}


export default App;
