import React from 'react';
//-------------- Bootstrap ------------------
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
//-------- Other import -------------------
import { Link } from 'react-router-dom';
import ReactGA from "react-ga";
import Image500 from '../../Assets/Images/500.png';
import { Helmet } from "react-helmet";
import { FaHome } from 'react-icons/fa';
//--------------Style--------------------------------
import classes from './PageError.module.css';

const trackPage = (page: string) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

const Page500 = () => {
    return (
        <>
            {trackPage('500')}
            <Helmet>
                <meta charSet="utf-8" />
                <title>500</title>
                <meta name="description" content='Server Error' />
                <meta name="robots" content="noindex" />
            </Helmet>
            <Container>
                <Row style={{ marginTop: '2rem' }}>
                    <Col className='text-left'>
                        <FaHome size={22} />
                        <Link to='/' className='colorRed' style={{ paddingLeft: 10 }}>
                            <span>Home</span>
                        </Link>
                    </Col>
                </Row>
                <Row className={classes.RowTitle}>
                    <Col xs={{ span: 12 }}>
                        <h1 className='colorRed'>500 errore del server</h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <span >Non possiamo compilare la tua richiesta c'e' stato un problema!, puoi tornare alla pagina home seguendo il link in alto a sinistra, o cambiare url di destinazione, grazie</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Image
                            className={classes.Image}
                            src={Image500}
                            alt='500-error-image-frutta'
                        />
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default Page500
