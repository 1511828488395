import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import classes from './SupplierDetailPage.module.css';

const ModalPreference = ({onHideModalPreference,hasPreference, choosenPreference, onChoosePreferenceHandler,
    onDeletePreferenceHandler, onConfirmProductAndPreferenceHandler, onCreatePreferenceTextHandler,
    isEdit, products, choosenProduct}) => {

    const checkIfCanConfirmHandler = () => {
        let isNotCliccable = false;
        hasPreference.forEach( listPreference => {
            let counterPreference = 0;
            listPreference.preferences.forEach( preference => {
                if(choosenPreference.find(pref => pref.id === preference.id)){
                    counterPreference ++;
                }
            })

            if(listPreference.hasMin && listPreference.min > counterPreference){
                isNotCliccable = true;
            }
        })

        if(isNotCliccable){
            return true;
        }else{
            return false;
        }
    
    }

    const checkIfAddPreferenceIsDisabled = (listPreference) => {
        let preferenceChoosen = 0;
        listPreference.preferences.forEach( prefe => {
            if(choosenPreference.filter(pref => pref.id === prefe.id).length){
                preferenceChoosen ++;
            }
        })
        
        if(listPreference && listPreference.hasMax && listPreference.max === preferenceChoosen ){
            return true;
        }

        return false;
    }

    return(<>
        <Modal show={true}  onHide={() => onHideModalPreference()}>
            <Modal.Header closeButton>
                <Row>
                    <Col>
                        <h3 className={classes.modalTitile}>{isEdit ? 'Modifica preferenze' : 'Scegli preferenze' }</h3>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body >
                <Row>
                    <Col><span style={{fontWeight: 'bolder', fontSize: 20}}>{products.find(product => product.id === choosenProduct).name}</span></Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col><span>{products.find(product => product.id === choosenProduct).description}</span></Col>
                </Row>
                {hasPreference && hasPreference.length ?
                    hasPreference.map( listPref => {
                    return(
                        <>
                            <Row style={{marginTop: 10}}>
                                <Col className='text-center' style={{backgroundColor: 'black', padding: 5}}>
                                    <span style={{color:'white'}}>{listPref && listPref.name}</span>
                                </Col>
                            </Row>
                            {listPref.hasMin ? <Row style={{marginTop: '1rem'}}><Col><span>{listPref.min === 1? `Seleziona almeno ${listPref.min} elemento` : `Seleziona almeno ${listPref.min} elementi`}</span></Col></Row> : null }
                            {listPref.hasMax ? <Row style={{marginTop: '1rem'}}><Col><span>{listPref.max === 1?`Seleziona massimo ${listPref.max} elemento` : `Seleziona massimo ${listPref.max} elementi`}</span></Col></Row> : null }
                            <Row style={{marginTop: '1rem'}}>
                            <Col className="text-center">
                                {listPref && listPref.preferences && listPref.preferences.map(preference => {
                                    return(
                                        <Row key={preference.id} style={{marginTop: 10}}>
                                            <Col xs={10} className='text-left'>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={ <span>{preference.name}</span>} 
                                                    checked={choosenPreference.find(choosenPref => choosenPref.id === preference.id)}
                                                    onChange={() => choosenPreference.find(choosenPref => choosenPref.id === preference.id)
                                                        ? onDeletePreferenceHandler(preference.id)
                                                        : onChoosePreferenceHandler(preference.id)
                                                    }
                                                    disabled={checkIfAddPreferenceIsDisabled(listPref) && !choosenPreference.find(choosenPref => choosenPref.id === preference.id) }
                                                />
                                                {/* 
                                                {choosenPreference.find(choosenPref => choosenPref.id === preference.id) ? 
                                                    <Button style={{width: 30, padding: 1}} className='buttonGeneralStyle' onClick={() => onDeletePreferenceHandler(preference.id) }>-</Button>
                                                :
                                                    <Button style={{width: 30, padding: 1}} className='buttonGeneralStyle' 
                                                        onClick={() => onChoosePreferenceHandler(preference.id) }
                                                        disabled={checkIfAddPreferenceIsDisabled(listPref)}
                                                    >
                                                        +
                                                    </Button>
                                                }
                                                */}
                                            </Col>
                                            <Col>
                                                <span>{preference.price ? preference.price+' €' : null } </span>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </Col>
                        </Row>
                        </>
                    )})
                : null }
                <Row style={{marginTop: '1rem'}}>
                    <Col className='text-center' style={{backgroundColor: 'black', padding: 5}}>
                        <span style={{color:'white'}}>Note aggiuntive</span>
                    </Col>
                </Row>
                <Row style={{marginTop: '1rem'}}>
                    <Col>
                        <textarea onChange={(event) => onCreatePreferenceTextHandler(event.target.value)} 
                            value={choosenPreference.find( preference => preference.type === 'text') ? choosenPreference.find( preference => preference.type === 'text').name : ''}
                            style={{width: '100%', height: 100}}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '2rem'}}>
                    <Col className='text-center'>
                        <Button className='buttonGeneralStyle' 
                            disabled={checkIfCanConfirmHandler()}
                            onClick={() => onConfirmProductAndPreferenceHandler()}
                            >
                            Conferma</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    
    </>)
}


export default ModalPreference;