import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
//------------------ Components import ---------------------
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import Step1, { Step1FormData } from '../../../Components/PrivatePages/ConfirmCartPage/Step1';
import Step2 from '../../../Components/PrivatePages/ConfirmCartPage/Step2';
import OrderConfirmStep from '../../../Components/PrivatePages/ConfirmCartPage/OrderConfirmStep';
import ModalNewCard from '../../../Components/PrivatePages/ConfirmCartPage/ModalNewCard';
//----------------- Other import -----------------
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ModalVerifyNumber from '../../../Components/PrivatePages/ConfirmCartPage/ModalVerifyNumber';
import { RootState } from '../../../store/reducer/rootReducer';
import moment, { Moment } from 'moment';
import Supplier from '../../../models/supplier';
import FirestoreService from '../../../services/firestore_service';
import FunctionService from '../../../services/function_service';
import Shift from '../../../models/shift';
import getAvailableShifts from '../../../functions/get_available_shifts';
import DiscountCode from '../../../models/discount_code';
import Settings from '../../../models/settings';
import Area from '../../../models/area';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import { PaymentMethod } from '@stripe/stripe-js';
import OrderProduct, { getOrderProducts } from '../../../models/order_product';
import { getCartAmount } from '../../../models/cart';
import { getAmountDetails } from '../../../functions/amount_helpers';
import OrderStates from '../../../models/order_states';
import { useStripe } from '@stripe/react-stripe-js';
import Order from '../../../models/order';
import firebase from '../../../config/fbConfig';
import Driver from '../../../models/driver';

export type PaymentMethodOption = "card" | "cash";
export type CashMethodOption = "amount" | "none";

const TRAVEL_DURATION_TOLERANCE = 60;
const TRAVEL_DISTANCE_TOLERANCE = 500;
const SHIFT_MIN_ORDER_TIME_DELTA = 8;


const ConfirmCartPage = () => {
    const [currentCart] = useState(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')!) : {});
    useFirestoreConnect({ collection: "suppliers", doc: currentCart.supplier_id });
    useFirestoreConnect({ collection: `suppliers/${currentCart.supplier_id}/products` });
    useFirestoreConnect({ collection: "areas" });
    useFirestoreConnect({ collection: "settings", doc: "settings", storeAs: "settings" });
    const settings: Settings | null = useSelector((state: RootState) => state.firestore.data.settings);
    const supplier: Supplier | null = useSelector((state: RootState) => state.firestore.ordered['suppliers']?.[0] ?? null);
    const area: Area | null = useSelector((state: RootState) => supplier === null ? null : (state.firestore.data.areas?.[supplier.areaId]) ?? null);
    const products = useSelector((state: RootState) => state.firestore.ordered[`suppliers/${currentCart.supplier_id}/products`]);
    const history = useHistory();
    const { auth, profile } = useSelector((state: RootState) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));
    const [errorApi, setErrorApi] = useState(false);
    const [step1Data, setStep1Data] = useState<Step1FormData | null>(null);
    const [date, setDate] = useState(moment());
    const [shiftLoading, setShiftLoading] = useState(true);
    const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
    const [availableShifts, setAvailableShifts] = useState<Shift[] | null>(null);
    const [shiftHasError, setShiftHasError] = useState(false);
    const [coordinates, setCoordinates] = useState<firebase.firestore.GeoPoint | null>(null);
    const [distance, setDistance] = useState<number | null>(null);
    const [addressError, setAddressError] = useState<string | null>(null);
    const [cartStep, setCartStep] = useState(1);
    const [modalVerificationNumber, setModalVerificationNumber] = useState(false);
    const [errorNumberVerification, setErrorNumberVerification] = useState(false);
    const [nextStepLoading, setNextStepLoading] = useState(false);
    //------ Second step state --------
    const [discountLoading, setDiscountLoading] = useState(false);
    const [discountCodeString, setDiscountCodeString] = useState('');
    const [discount, setDiscount] = useState<DiscountCode | null>(null);
    const [discountError, setDiscountError] = useState<string | null>(null);
    const [paymentMethodOption, setPaymentMethodOption] = useState<PaymentMethodOption>('card'); //cash;
    const [cashMethod, setCashMethod] = useState<CashMethodOption>('amount'); //none
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
    const [cashQuantity, setCashQuantity] = useState<number | null>(null);
    const [showCardModal, setShowCardModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmError, setConfirmError] = useState<string | null>(null);
    const stripe = useStripe();

    useEffect(() => {
        if (isLoaded(profile) && isEmpty(profile) && isLoaded(auth) && isEmpty(auth)) {
            history.push('/');
        }
    }, [profile, auth, history]);

    useEffect(() => {
        if (supplier !== null && availableShifts === null)
            updateAvailableShifts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supplier, availableShifts]);


    async function updateAvailableShifts() {
        setShiftLoading(true);
        let shifts = await getAvailableShifts(supplier!);
        setAvailableShifts(shifts)
        setShiftLoading(false);
    }

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    //-------------------------- First Step function --------------------------

    const onConfirmFirstStepCart = async (data: Step1FormData) => {
        setStep1Data(data);
        if (selectedShift === null) {
            setShiftHasError(true);
            return false;
        }

        const firestore = FirestoreService.getInstance();
        const functions = FunctionService.getInstance();

        setNextStepLoading(true);
        if (!await firestore.checkPhoneNumberVerified(auth.uid, data.phoneNumber)) {
            functions.sendPhoneNumberConfirmMessage(data.phoneNumber);
            setModalVerificationNumber(true);
            setNextStepLoading(false);
            return;
        }

        verifyAddress(data.address, data.streetNumber);
    }

    const verifyAddress = async (address: string, streetNumber: string) => {
        setNextStepLoading(true);

        const functions = FunctionService.getInstance();

        const result = await functions.calcAddressCoordinatesAndDistance(address, streetNumber, supplier!.geohashPoint.geopoint);

        if (result.coordinates === null) {
            setAddressError("L'indirizzo inserito non è valido.");
            setNextStepLoading(false);
            return;
        }

        if ((result.distance !== null && result.distance > area!.maxTravelDistance * 1000 + TRAVEL_DISTANCE_TOLERANCE) ||
            (result.duration !== null && result.duration > area!.maxTravelDuration * 60 + TRAVEL_DURATION_TOLERANCE)) {
            setAddressError("L'indirizzo inserito è al di fuori dell'area di consegna del fornitore.");
            setNextStepLoading(false);
            return;
        }

        console.log(result)

        setDistance(result.distance);
        setCoordinates(new firebase.firestore.GeoPoint(result.coordinates.latitude, result.coordinates.longitude));
        setNextStepLoading(false);

        setCartStep(2);
    }

    const verifyNumberHandler = async (code: string) => {
        const functions = FunctionService.getInstance();

        setNextStepLoading(true);
        setErrorNumberVerification(false);

        let result = await functions.verifyPhoneNumber(step1Data!.phoneNumber, code);

        if (result.success) {
            setModalVerificationNumber(false);
            verifyAddress(step1Data!.address, step1Data!.streetNumber);
        }
        else {
            setErrorNumberVerification(true);
            setNextStepLoading(false);
        }
    }

    const onShiftChange = (shift: Shift) => {
        setSelectedShift(shift);
        setShiftHasError(false);
        setDiscount(null);
    }

    const onDateChange = (date: Moment) => {
        setSelectedShift(null);
        setDate(date);
        setDiscount(null);
    }


    //-------------------------------- Second Step function --------------------------------------


    const confirmDiscountCodeHandler = async () => {
        const functions = FunctionService.getInstance();
        const firestore = FirestoreService.getInstance();

        setDiscountLoading(true);
        setDiscountError(null);

        let result = await functions.checkIsDiscountUsable(discountCodeString, supplier!.reference.id, supplier!.areaId, selectedShift!.endTime.toMillis());
        if (result.success && result.usable) {
            setDiscount(await firestore.getDiscountCodeById(result.codeId));
        } else if (!result.usable) {
            setDiscountError(result.reason);
        } else {
            setDiscountError(result.error!);
        }

        setDiscountLoading(false);
    }

    //-----------------------------------------------------------------------------------------

    async function processOrder(): Promise<boolean> {
        if (settings === null || area === null || supplier === null || selectedShift === null)
            return false;

        setConfirmLoading(true);
        let driverId: string | null = null;
        let discountUsed = false;
        try {
            await _checkShift();

            if (supplier.useOwnDrivers !== true)
                // Cerca un potenziale fattorino per l'ordine
                driverId = await findDriver();

            // Assegna un id a 10 cifre all'ordine
            let orderId = Math.floor(Math.random() * 10000000000).toFixed(0);

            // Ottiene la lista di OrderProductModel
            let orderProducts = getOrderProducts(currentCart.products, products);

            if (discount !== null) {
                await setDiscountAsUsed(orderId);
                discountUsed = true;
            }

            // Calcola il totale dei prodotti
            let cartAmount = getCartAmount(currentCart, products);

            // Ottiene il prezzo per la spedizione, la percentuale del fornitore e il compenso del fattorino
            let amountDetails = getAmountDetails(cartAmount, discount, settings!, area, supplier, distance);
            let deliveryAmount = amountDetails.deliveryAmount;
            let discountAmount = amountDetails.discountAmount;
            let totalToPay = amountDetails.totalAmount;

            let supplierPercentage = amountDetails.supplierPercentage;
            let driverAmount = amountDetails.driverAmount;

            let paymentIntentId: string | null = null;
            if (paymentMethodOption === "card") {
                // Crea il PaymentIntent
                paymentIntentId = await processPayment(orderId, totalToPay, false);
            }

            // Conferma l'ordine e lo salva nel database
            await confirmOrder(supplier.useOwnDrivers === true,
                driverId,
                paymentIntentId,
                orderId, cartAmount,
                deliveryAmount,
                discountAmount,
                supplierPercentage,
                driverAmount,
                orderProducts,
                supplier.responsibleForCash === true,
            );
            console.log(orderId);
            setConfirmLoading(false);
            localStorage.removeItem("cart");
            setCartStep(3);
        } catch (err: any) {
            const functions = FunctionService.getInstance();
            if (driverId !== null) {
                await functions.setDriverAsAvailable(driverId, selectedShift.startTime);
            }
            if (discountUsed && discount !== null) {
                await functions.setCodeAsReusable(discount.id);
            }
            setConfirmLoading(false);
            setConfirmError(err.message ?? "Errore inaspettato durante l'invio dell'ordine.");
            console.log(err);
        }

        return true;
    }


    async function _checkShift(): Promise<void> {
        let endTimeMoment = moment(selectedShift!.endTime.toMillis());
        let now = moment();

        if (endTimeMoment.diff(now, "minutes") < supplier!.minOrderTime - SHIFT_MIN_ORDER_TIME_DELTA) throw new Error("Non è più possibile effettuare l'ordine in quanto il fornitore non accetta più ordini nella fascia oraria selezionata.");

        const db = FirestoreService.getInstance();
        var isBlocked = await db.checkIfShiftIsBlocked(supplier!.reference.id, selectedShift!.startTime);
        if (isBlocked) throw new Error("Non è più possibile effettuare l'ordine in quanto il fornitore non accetta più ordini nella fascia oraria selezionata.");
        var weekday = moment(selectedShift!.startTime.toMillis()).weekday();

        var startTimeString = moment(selectedShift!.startTime.toMillis()).format("HH:mm");
        var maxOrderCount = await db.getMaxOrderCountForWeekday(supplier!.reference.id, weekday);
        if (maxOrderCount != null && maxOrderCount.maxOrders[startTimeString] > -1) {
            var shiftOrderCount = await db.getShiftOrderCount(supplier!.reference.id, selectedShift!.startTime);
            if (shiftOrderCount >= maxOrderCount.maxOrders[startTimeString]) throw new Error("Non è più possibile effettuare l'ordine in quanto il fornitore non accetta più ordini nella fascia oraria selezionata.");
        }
    }

    async function findDriver(): Promise<string> {
        const functions = FunctionService.getInstance();
        let result = await functions.findDriverForOrder(supplier!.reference.id, coordinates!, selectedShift!, paymentMethodOption === "cash");

        if (!result.success) {
            updateAvailableShifts();
            throw new Error("Nessun fattorino disponibile nell'orario selezionato.");
        }

        return result.driverId!;
    }

    async function setDiscountAsUsed(orderId: string) {
        if (discount === null) return;

        const functions = FunctionService.getInstance();

        let result = await functions.setDiscountAsUsed(
            discount.id,
            orderId,
            supplier!.reference.id,
            supplier!.areaId,
            selectedShift!.endTime.toMillis(),
        );
        if (!result.success) throw Error(result.error);
    }

    async function processPayment(orderId: string, totalToPay: number, savePaymentMethod: boolean): Promise<string> {
        const functions = FunctionService.getInstance();
        let result = await functions.createPaymentIntent(paymentMethod!.id, totalToPay, orderId, savePaymentMethod);
        let confirmPaymentResult;
        try {
            confirmPaymentResult = await stripe!.confirmCardPayment(result.clientSecret!, { payment_method: paymentMethod!.id });
        } catch (err) {
            throw new Error("C'è stato un errore durante il pagamento. Ricontrolla i dati inseriti.");
        }
        if (confirmPaymentResult.paymentIntent?.status !== 'requires_capture')
            throw new Error("C'è stato un errore durante il pagamento. Ricontrolla i dati inseriti.");
        return confirmPaymentResult.paymentIntent.id;
    }

    async function confirmOrder(
        useOwnDrivers: boolean,
        driverId: string | null,
        paymentIntentId: string | null,
        orderId: string,
        cartAmount: number,
        deliveryAmount: number,
        discountAmount: number,
        supplierPercentage: number,
        driverAmount: number,
        products: OrderProduct[],
        supplierResponsibleForCash: boolean,
    ): Promise<boolean> {
        const db = FirestoreService.getInstance();
        let customer = await db.getUserById(auth.uid);
        let driver: Driver | null = null;
        if (!useOwnDrivers)
            driver = await db.getDriverById(driverId!);

        let order: Order = {
            customerId: customer.uid,
            driverId: useOwnDrivers ? null : driverId,
            supplierId: supplier!.reference.id,
            notes: step1Data!.driverNotes,
            shiftStartTime: selectedShift!.startTime,
            preferredDeliveryTimestamp: selectedShift!.endTime,
            productCount: products.reduce((count, product) => count + product.quantity, 0),
            cartAmount: parseFloat(cartAmount.toFixed(2)),
            deliveryAmount: parseFloat(deliveryAmount.toFixed(2)),
            supplierPercentage: supplierPercentage,
            driverAmount: useOwnDrivers ? null : parseFloat(driverAmount.toFixed(2)),
            state: OrderStates.NEW,
            customerImageUrl: customer.imageUrl ?? null,
            customerName: step1Data!.nominative,
            customerCoordinates: coordinates!,
            customerAddress: step1Data!.address,
            customerStreetNumber: step1Data!.streetNumber,
            customerPhoneNumber: step1Data!.streetNumber,
            driverImageUrl: useOwnDrivers ? null : driver!.imageUrl,
            driverName: useOwnDrivers ? null : driver!.nominative,
            driverPhoneNumber: useOwnDrivers ? null : driver!.phoneNumber,
            supplierImageUrl: supplier!.imageUrl,
            supplierName: supplier!.name,
            supplierCoordinates: supplier!.geohashPoint.geopoint,
            supplierAddress: supplier!.address,
            supplierPhoneNumber: supplier!.phoneNumber,
            paymentIntentId: paymentIntentId,
            creationTimestamp: firebase.firestore.Timestamp.fromMillis(moment().valueOf()),
            products: products,
            discountAmount: parseFloat(discountAmount.toFixed(2)),
            discountCode: discount?.code ?? null,
            discountCodeId: discount?.id ?? null,
            discountPercentage: discount?.hasFixedAmount === false ? discount.percentage : null,
            useCashPayment: paymentMethodOption === "cash",
            cashAmount: (paymentMethodOption === "cash" && cashMethod === "amount") ? cashQuantity : null,
            areaId: supplier!.areaId,
            points: Math.floor(cartAmount - discountAmount),
            distance: distance,
            useOwnDrivers: useOwnDrivers,
            supplierResponsibleForCash: supplierResponsibleForCash,
        };

        await db.createOrder(
            orderId,
            order,
        );

        return true;
    }

    return (<>
        {errorApi ? <AlertGeneral setTrigger={setErrorApi} closeAlone={false} color='danger' description={"Problema nel confermare il tuo ordine"} /> : null}
        {modalVerificationNumber ?
            <ModalVerifyNumber verifyNumberHandler={verifyNumberHandler}
                errorNumberVerification={errorNumberVerification}
                nextStepLoading={nextStepLoading}
            />
            : null}
        <ModalNewCard show={showCardModal} onClose={() => setShowCardModal(false)} onCardConfirm={(card) => {
            setPaymentMethod(card);
            setShowCardModal(false);
        }} />
        <Container fluid className='containerStyle'>
            <Row style={{ marginTop: '1rem' }}>
                <Col><h1 style={{ color: '#E22E2E' }}>Checkout</h1></Col>
            </Row>
            {
                (supplier === null || area === null || settings === null) ?
                    <LoadingButton /> :
                    <Row style={{ marginTop: '1rem' }}>
                        <Col xs={12} lg={{ span: 8, offset: 2 }}>
                            <Card style={{ padding: 20 }}>
                                <Row>
                                    <Col>
                                        {cartStep === 1 ?
                                            <Step1 onConfirmFirstStepCart={onConfirmFirstStepCart}
                                                onDateChange={onDateChange} date={date} availableShifts={availableShifts}
                                                shiftLoading={shiftLoading} onShiftChange={onShiftChange}
                                                shiftHasError={shiftHasError} selectedShift={selectedShift}
                                                nextStepLoading={nextStepLoading} updateAvailableShifts={updateAvailableShifts}
                                                addressError={addressError}
                                            />
                                            : cartStep === 2 ?
                                                (confirmLoading ? <LoadingButton /> :
                                                    <Step2 discount={discount} discountText={discountCodeString} discountError={discountError}
                                                        onChangeDiscountCode={setDiscountCodeString} confirmDiscountCodeHandler={confirmDiscountCodeHandler}
                                                        paymentMethodOption={paymentMethodOption} setPaymentMethodOption={setPaymentMethodOption}
                                                        cashMethod={cashMethod} cashQuantity={cashQuantity} changeCashMethod={setCashMethod}
                                                        setCashQuantity={setCashQuantity} onOpenCardModal={() => setShowCardModal(true)}
                                                        supplier={supplier} products={products} currentCart={currentCart} settings={settings}
                                                        discountLoading={discountLoading} resetDiscountCode={() => setDiscount(null)} area={area}
                                                        paymentMethod={paymentMethod} processOrder={processOrder}
                                                        confirmError={confirmError}
                                                    />
                                                )
                                                : <OrderConfirmStep />

                                        }
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
            }

        </Container>
    </>)
}


export default ConfirmCartPage;