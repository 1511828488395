import firebase from 'firebase/app'

type UserRole = "CUSTOMER" | "ADMIN" | "SUPPLIER_ADMIN" | "DRIVER";

async function getUserRole(credentials: firebase.auth.UserCredential): Promise<UserRole> {
  let idToken = await credentials.user!.getIdTokenResult(true);
  console.log(idToken.claims);
  if (idToken.claims['admin'] === true) return "ADMIN";
  if (idToken.claims['supplierAdmin'] === true) return "SUPPLIER_ADMIN";
  if (idToken.claims['driver'] === true) return "DRIVER";
  return "CUSTOMER";
}

export default getUserRole;