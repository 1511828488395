import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
//------------------- Other import ---------------------
import App from '../../../Assets/Images/APP.png';
import Apple from '../../../Assets/Images/apple_store.png';
import Google from '../../../Assets/Images/google_play.png';
import classes from './Home.module.css';
import {AiFillApple} from 'react-icons/ai';
import {FaGooglePlay} from 'react-icons/fa';


const Download = () => {
    return(
        <>
            <Row>
                <Col>
                    <Row style={{marginTop: '2rem', marginBottom: '2rem'}}>
                        <Col lg={5}>
                            <Image src={App} style={{width: '60%'}}/>
                        </Col>
                        <Col className='text-center'>
                            <Row style={{marginTop: '1rem'}}>
                                <Col >
                                    <span className={classes.downloadTitle}>Scarica la nostra app</span>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col >
                                    <span>Disponibile su google store e play store</span>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '3rem'}}>
                                <Col lg={{span: 6, offset: 3}}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <a href="https://apps.apple.com/it/app/taporty/id1510551452" target='_blank' >
                                                <Image src={Apple} style={{width: '100%'}}/>
                                            </a >
                                        </Col>
                                        <Col className={classes.marginDownload}>
                                            <a href="https://play.google.com/store/apps/details?id=it.taporty.customer&hl=en&gl=US" target='_blank' >
                                                <Image src={Google} style={{width: '100%'}}/>
                                            </a >
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{height: 50, width: '100%', backgroundColor: '#EB4D4D', marginTop: '-5rem'}}
                className={classes.displayRedLine}
            />
        </>
    )
}


export default Download;