import React, { useState, useEffect } from 'react';
//----------- Bootstrap --------------------------
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//-------------- Components ----------------
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase';
//------------ Style --------------------
import classes from './Login.module.css'
import { RootState } from '../../../store/reducer/rootReducer';
import firebase from 'firebase';
import GoogleImage from '../../../Assets/Images/google_logo.png'
import getUserRole from '../../../functions/get_user_role';
import { toastr } from 'react-redux-toastr'
import FirestoreService from '../../../services/firestore_service';

interface FormObject {
    email: 'string';
    password: 'string';
}



const Login = () => {
    const [errorApi, setErrorApi] = useState<true | false>(false);
    const [loadingApi, setLoadingApi] = useState<true | false>(false);
    const [emailConfirmation, setEmailConfirmation] = useState<true | false>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const firebaseApi = useFirebase();
    const { auth, profile } = useSelector((state: RootState) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const emailValidator = (value: string) => value.trim().match(/^.+@.+$/) || 'Email non valida';

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const onClickLoginHandler = async (data: FormObject) => {
        try {
            const auth = firebaseApi.auth();
            const authResult = await auth.signInWithEmailAndPassword(data.email, data.password);

            const role = await getUserRole(authResult);
            if (role !== "CUSTOMER") {
                await auth.signOut();
                history.push("/accedi");
                toastr.error("Errore durante il login!", "Non sei un cliente della piattaforma!");
            }

            setLoadingApi(false);
        } catch (err) {
            console.log(err);
            setErrorApi(true);
            setLoadingApi(false);
        }
    }

    useEffect(() => {
        if (!isEmpty(auth) && isLoaded(auth)) {
            history.push('/')
        }
    }, [auth])

    const signInWithGoogle = async () => {
        const auth = firebase.auth();
        const googleProvider = new firebase.auth.GoogleAuthProvider();

        try {
            const authResult = await auth.signInWithPopup(googleProvider);

            const role = await getUserRole(authResult);
            if (role !== "CUSTOMER") {
                await auth.signOut();
                history.push("/accedi");
                toastr.error("Errore durante il login!", "Non sei un cliente della piattaforma!");
                setLoadingApi(false);
                return;
            }

            const firestore = FirestoreService.getInstance();
            if (!await firestore.userExists(authResult.user!.uid)) {
                await firestore.createUser(authResult.user!.uid, authResult.user!.displayName ?? "", authResult.user!.email!);
            }

            setLoadingApi(false);
        }
        catch (err) {
            console.log(err);
            toastr.error("Errore durante il login!", "Errore inaspettato durante il login!");
        }
    }

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Accedi</title>
            <meta name="description" content='Accedi a Topaty' />
        </Helmet>
        <Container fluid style={{ paddingBottom: 40 }}>
            { /* emailConfirmation ?
                 <AlertGeneral closeAlone={false} description={"La tua email non è stata ancora confermata. Accedi alla tua casella di posta e clicca sul tasto Conferma Email."} color='danger' setTrigger={setEmailConfirmation} /> 
            : null */}
            <Row style={{ marginTop: '2rem' }}>
                <Col xl={{ span: 8, offset: 2 }}>
                    <Card className={classes.card} >
                        <Row><Col><h1 className={classes.titleStyle}>Accedi</h1></Col></Row>
                        <Form onSubmit={handleSubmit(onClickLoginHandler)} >
                            <Row><Col>
                                <Row style={{ marginTop: '2rem' }}>
                                    <Col xs={12} lg={12}>
                                        <TextBox ref={register({ validate: emailValidator })} placeHolder={'Inserisci la tua email'} type={'email'} name={'email'}
                                            error={errors.email ? errors.email.message : ''}
                                            labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                            offset={1} span={10} labelName={'Email'}
                                        />
                                    </Col>
                                    <Col style={{ marginTop: '1rem' }}>
                                        <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci la tua password'} type={'password'} name={'password'}
                                            error={errors.password ? 'Campo richiesto' : ''} offset={1} span={10} labelName={'Password'}
                                            labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                        />
                                    </Col>
                                </Row>
                                {errorApi ? <>
                                    <Row style={{ marginTop: '1rem' }}>
                                        <Col>
                                            <span className='errorStyle'>Password o email errati</span>
                                        </Col>
                                    </Row>
                                </>
                                    : null}
                                <Row style={{ marginTop: '2rem' }}>
                                    <Col>
                                        <div className="login-buttons">
                                            <button onClick={signInWithGoogle} style={{ backgroundColor: "white", borderRadius: "6px", border: "1px solid #AAAAAA" }}>
                                                <img src={GoogleImage} alt="google icon" height="30px" />
                                                <span> Accedi con google</span>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '2.5rem' }}>
                                    <Col>
                                        {loadingApi ? <LoadingButton />
                                            : <Button type='submit' className={'buttonGeneralStyle'} style={{ width: 100 }}>
                                                Accedi
                                    </Button>
                                        }
                                    </Col>
                                </Row>
                            </Col></Row>
                        </Form>
                        <Row style={{ marginTop: '1rem' }}>
                            <Col>
                                <span>Non hai un account ? </span>
                                <Link to='/registrati'>
                                    <span className={classes.linkStyle}>Registrati</span>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link to='/password-dimenticata'>
                                    <span className={classes.linkStyle}>Password dimenticata?</span>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>)
}


export default Login