import React, { forwardRef } from 'react'
import Form from 'react-bootstrap/Form';
import classes from './FormComponent.module.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


interface Props {
    name?: string;
    placeHolder?: string;
    labelName?: string;
    labelStyle?: {};
    error?: string;
    textarea?: boolean;
    type?: string;
    inputStyle?: {};
    offset?: number;
    span?: number;
    rows?: number;
    disabled?: boolean;
    errorStyle?: {};
}
const TextBox = forwardRef<HTMLTextAreaElement, Props>(({ name, placeHolder, labelName, labelStyle, error, textarea, type, inputStyle, offset, span, rows, disabled, errorStyle }, ref) => {
    return (<>
        <Row>
            <Col >
                <Form.Label >
                    <span className={'formLabelStyle'} style={labelStyle}>{labelName}</span>
                </Form.Label>
            </Col>
        </Row>
        <Row >
            <Col md={{ span: span, offset: offset }}>
                <Form.Control type={type} as={textarea ? 'textarea' : 'input'} name={name}
                    placeholder={placeHolder}
                    className='textStyle'
                    style={inputStyle}
                    ref={ref}
                    disabled={disabled}
                />
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col>
                {error && (<span className='errorStyle' style={errorStyle}>{error}</span>)}
            </Col>
        </Row>
    </>
    )
})

export default TextBox;