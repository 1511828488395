import React, { Fragment, useState } from 'react';
//------------------------ Import Bootstrap -------------------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//------------------------ Blueprint ---------------------------
import { Button } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
//--------------------------Import BluePrint --------------------------------
import { MenuItem } from "@blueprintjs/core";
//---------------- Leaflet ---------------------------
import { Select } from "@blueprintjs/select";
import "@blueprintjs/select/lib/css/blueprint-select.css"
//------------------ Import Css ----------------------
import classes from './ChoosingAddress.module.css';
import AlertGeneral from '../AlertGeneral/AlertGeneral';


const ChooseAddress = (props) => {
    const [suggestedAddressByResearch, setSuggestedAddressByResearch] = useState([]);
    const [address, setAddress] = useState('');
    const [errorApi, setErrorApi] = useState(false);

    const onChangeAddressHandler = (query) => {
        fetch('https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=' + query + '&maxSuggestions=5&f=json',
            {
                method: 'GET',
            })
            .then(res => res.ok ? res.json() : null)
            .then(result => {
                if (result) {
                    if ('suggestions' in result) {
                        setSuggestedAddressByResearch(result.suggestions);
                    } else {
                        setSuggestedAddressByResearch([]);
                    }
                } else {
                }
            }).catch(error => {
                setErrorApi(true);
            });

        setAddress(query);
    }

    const renderItemHandler = (items, props) => {
        return (
            <MenuItem
                disabled={false}
                key={items.magicKey}
                text={items.text}
                onClick={props.handleClick}
            />
        )
    }

    const onPickAddressHandler = (address) => {
        fetch(' https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?outSr=4326&forStorage=false&outFields=*&maxLocations=5&singleLine=' + address.text + '&magicKey=' + address.magicKey + '&f=json',
            {
                method: 'GET',
            })
            .then(res => res.ok ? res.json() : null)
            .then(result => {
                if (result) {
                    props.setAddress({
                        address: result.candidates[0].address,
                        longitude: result.candidates[0].location.x,
                        latitude: result.candidates[0].location.y,
                        zipCode: result.candidates[0].attributes.Postal,
                    })
                } else {
                    setErrorApi(true);
                }
            }).catch(error => {
                setErrorApi(true);
            });
    }


    return (
        <Fragment >
            {errorApi && <AlertGeneral color='danger' description="C'e' stato un errore" />}
            <Row>
                <Col>
                    <Select
                        className={classes.SelectRegistrationAddress}
                        inputProps={{ placeholder: "Scrivi l'indirizzo" }}
                        onItemSelect={(item) => onPickAddressHandler(item)}
                        itemRenderer={renderItemHandler}
                        items={suggestedAddressByResearch}
                        filterable={true}
                        onQueryChange={onChangeAddressHandler}
                        disabled={props.buttonChooseDataPressed}
                    >
                        <Button
                            icon="filter"
                            rightIcon="caret-down"
                            text={props.address.address ? props.address.address : "Scrivi l' indirizzo"}
                            disabled={props.buttonChooseDataPressed}
                            className={classes.AddressButton}
                        />
                    </Select>
                </Col>
            </Row>
        </Fragment>
    )
}


export default ChooseAddress;