import { Fragment } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classes from './ConfirmModal.module.css';


const ConfirmModal = ({ handleCancel, title, description, handleConfirm, show }) => {

    const titleSize = title.length > 10 ? '2rem' : '3.1rem'

    return (
        <Modal size={`lg`} show={show} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Row>
                    <Col>
                        <span style={{ fontSize: titleSize, color: '#008489' }} className={classes.ModalTitle}>{title}</span>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body >
                <Row>
                    <Col className="text-center">
                        <span className={classes.ModalDescription}>{description}</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col className="text-center">
                        <Button className='buttonGeneralStyle' onClick={handleCancel}>
                            Annulla
                            </Button>
                    </Col>
                    <Col className="text-center">
                        <Button className='buttonGeneralStyle' onClick={handleConfirm}>
                            Conferma
                            </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}


export default ConfirmModal;