import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CartPage from '../../PublicPages/SupplierDetailPage/CartPage';
import { GiCash } from 'react-icons/gi';
import { AiFillCreditCard } from 'react-icons/ai'
import { CashMethodOption, PaymentMethodOption } from '../../../Containers/PrivatePages/ConfirmCartPage/ConfirmCartPage';
import Supplier from '../../../models/supplier';
import Product from '../../../models/product';
import DiscountCode from '../../../models/discount_code';
import { CloseButton } from 'react-bootstrap';
import Settings from '../../../models/settings';
import Cart from '../../../models/cart';
import Area from '../../../models/area';
import { PaymentMethod } from '@stripe/stripe-js';

type Step2Props = {
    discount: DiscountCode | null,
    discountText: string,
    onChangeDiscountCode: (value: string) => any,
    discountError: string | null,
    confirmDiscountCodeHandler: () => any,
    paymentMethodOption: PaymentMethodOption,
    setPaymentMethodOption: (val: PaymentMethodOption) => any,
    cashMethod: CashMethodOption,
    changeCashMethod: (val: CashMethodOption) => any,
    cashQuantity: number | null,
    setCashQuantity: (val: number | null) => any,
    supplier: Supplier,
    onOpenCardModal: () => any,
    resetDiscountCode: () => any;
    paymentMethod: PaymentMethod | null,
    products: Product[],
    settings: Settings,
    currentCart: Cart,
    area: Area,
    discountLoading: boolean;
    processOrder: () => any;
    confirmError: string | null;
}

const Step2: FunctionComponent<Step2Props> = ({ onChangeDiscountCode, discountText, confirmDiscountCodeHandler, discountError,
    paymentMethodOption, setPaymentMethodOption, cashMethod, cashQuantity, changeCashMethod, paymentMethod,
    setCashQuantity, supplier, settings, products, currentCart, onOpenCardModal, discountLoading, discount, resetDiscountCode, area, processOrder, confirmError, }) => {
    return (<>
        <Row><Col><span>Inserisci codice sconto</span></Col></Row>

        {
            discount === null &&
            <>
                <Row style={{ marginTop: 10 }}>
                    <Col>
                        <input value={discountText} onChange={(event) => onChangeDiscountCode(event.target.value)} />
                        <Button style={{ marginLeft: 10 }} className='buttonGeneralStyle' onClick={() => confirmDiscountCodeHandler()} disabled={discountLoading}>Conferma</Button>
                    </Col>
                </Row>
                {discountError !== null &&
                    <Row>
                        <Col><span className='text-danger'>{discountError}</span></Col>
                    </Row>
                }
            </>
        }
        {
            discount !== null &&
            <div className="d-flex justify-content-center">
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={1} className='my-auto'>
                            <AiFillCreditCard />
                        </Col>
                        <Col className='my-auto'>
                            <div><b>{discount.code}</b></div>
                            {
                                discount.hasFixedAmount && discount.amount > 0 &&
                                <div>Codice sconto di {discount.amount.toFixed(2)}€</div>
                            }
                            {
                                !discount.hasFixedAmount && discount.percentage > 0 &&
                                <div>Codice sconto del {(discount.percentage * 100).toFixed(2)}%</div>
                            }
                            {
                                discount.hasFreeDelivery &&
                                <div>Spedizione gratuita</div>
                            }
                        </Col>
                        <Col xs={1} className='my-auto'>
                            <CloseButton onClick={resetDiscountCode} />
                        </Col>
                    </Row>
                </Card>
            </div>
        }

        <Row style={{ marginTop: '1rem' }}>
            <Col lg={{ span: 8, offset: 2 }}>
                <Card style={{ padding: 10 }}>
                    <CartPage supplier={supplier} settings={settings} products={products} currentCart={currentCart}
                        isSummary={true} isEditOrder={false} discount={discount} area={area}
                    />
                </Card>
            </Col>
        </Row>

        {
            (area.cashPaymentAvailable || paymentMethodOption === 'cash') &&
            <Row style={{ marginTop: 30 }}>
                <Col>
                    <Form.Check
                        type="radio"
                        id="cash"
                        className="mb-2"
                        checked={paymentMethodOption === 'cash'}
                        onChange={() => setPaymentMethodOption('cash')}
                        label={<><GiCash size={25} /> <span>Pagamento in contanti</span> </>}
                    />
                </Col>
            </Row>
        }
        {
            paymentMethodOption === 'cash' ?
                <>
                    <Row style={{ marginTop: 20 }}>
                        <Col><span>Con quanto hai intenzione di pagare ?</span></Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Col>
                            <Form.Check
                                type="radio"
                                id="cash"
                                className="mb-2"
                                checked={cashMethod === 'amount'}
                                onChange={() => changeCashMethod('amount')}
                                label={<input value={cashQuantity === null ? "" : cashQuantity} type="number" min="0" max="100" step="0.01" onChange={(e) => {
                                    if (e.target.value === "") {
                                        setCashQuantity(null);
                                        return;
                                    }
                                    setCashQuantity(parseFloat(e.target.value));
                                }}
                                    placeholder={"Inserisci l'importo"}
                                    style={{ minWidth: "300px" }}
                                />}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <Form.Check
                                type="radio"
                                id="cash"
                                className="mb-2"
                                checked={cashMethod === 'none'}
                                onChange={() => changeCashMethod('none')}
                                label={<span>Non lo so</span>}
                            />
                        </Col>
                    </Row>
                </>
                : null
        }

        <Row style={{ marginTop: 30 }}>
            <Col>
                <Form.Check
                    type="radio"
                    id="card"
                    className="mb-2"
                    checked={paymentMethodOption === 'card'}
                    onChange={() => setPaymentMethodOption('card')}
                    label={<> <AiFillCreditCard size={25} /> <span>Pagamento con carta</span> </>}
                />
            </Col>
        </Row>
        {
            paymentMethodOption === 'card' ?
                <>
                    <Row style={{ marginTop: 10 }}>
                        <Col>
                            <Button className='buttonGeneralStyle' onClick={onOpenCardModal}>{paymentMethod === null ? "Aggiungi carta" : "Modifica carta"}</Button>
                        </Col>
                    </Row>
                </>
                : null
        }
        {confirmError !== null && <div className="mt-2" style={{ fontSize: "12px", color: "red" }}>{confirmError}</div>}
        <Row style={{ marginTop: 10 }}>
            <Col>
                <Button className='buttonGeneralStyle' type="button"
                    disabled={(paymentMethodOption === "card" && paymentMethod === null) ||
                        (paymentMethodOption === "cash" && cashMethod === "amount" && cashQuantity === null)} onClick={processOrder}>
                    Invia ordine
                </Button>
            </Col>
        </Row>
    </>)
}


export default Step2;