import React from 'react'


const Privacy = () => {
    return(<>
    
    
    </>)
}


export default Privacy;