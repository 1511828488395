import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import classes from './SupplierDetailPage.module.css';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const MainPage = ({ menu, onChangeVisibilityCategoryHandler, menuFiltered, onClickAddCartHandler
    , currentCart, onClickAddSameItemInCart, onClickDeleteSameItemInCart, onClickEditItemHandler,
    isEditOrder }) => {

    const renderMenu = () => {
        if (menuFiltered.length) {
            return menuFiltered
        }

        return menu;
    }

    const renderListPreferencesIfExist = (productId, productInCart) => {
        let listPreference = null;
        if (isEditOrder) { //Se e' un Edit order
            if (productInCart.preferences && productInCart.preferences.length) {
                listPreference = productInCart.preferences.map(preference => {
                    return (
                        `${preference.name} `
                    )
                })
            }
        } else {
            if (productInCart.preferences && productInCart.preferences.length) {
                listPreference = productInCart.preferences.map(preference => {
                    return (
                        `${preference.name} `
                    )
                })
            }
        }

        return listPreference;
    }

    const renderNewPrice = (productPrice, productId, productInCart) => {
        let newPrice = productPrice;
        if (productInCart.preferences && productInCart.preferences.length) {
            productInCart.preferences.forEach(preference => {
                if (preference.price) {
                    newPrice += preference.price
                }
            })
        }

        return newPrice.toFixed(2);
    }

    return (<>
        {renderMenu().map((item, index) => {
            return (<>
                <Row key={item.categoryId} style={{ marginTop: index ? 10 : 0 }}>
                    <Col className='text-left'>
                        <span className={classes.maninPageCategoryName}>
                            {item.categoryName}
                        </span>
                    </Col>
                    <Col xs={2} className='text-left'>
                        {item.isVisible ?
                            <BsChevronDown size={22} onClick={() => onChangeVisibilityCategoryHandler(item.categoryId)} />
                            : <BsChevronUp size={22} onClick={() => onChangeVisibilityCategoryHandler(item.categoryId)} />}
                    </Col>
                </Row>
                <div style={{ height: 1, backgroundColor: '#E22E2E' }} />
                { item.isVisible ?
                    <Row>
                        {item.products.map((product, index) => {
                            return (
                                <Col key={index} xs={12} style={{ marginTop: 10 }} className='text-left'>
                                    <Row>
                                        <Col xs={10}>
                                            <span style={{ fontWeight: 'bold', fontSize: 14 }}>{product.name}</span>
                                        </Col>
                                        <Col xs={2}>
                                            <span>€ {product.price.toFixed(2)} </span>
                                            { /*currentCart.products.find( productInCart => productInCart.id === product.id) ? 
                                            <Button className='buttonGeneralStyle' style={{width: 40, marginLeft: 10}}
                                                onClick={() => onClickAddCartHandler(product.id)}
                                            >-</Button>
                                        : */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><span>{product.description}</span></Col>
                                        <Col xs={2}>
                                            <Button className='buttonGeneralStyle' style={{ width: 30, padding: 1 }}
                                                onClick={() => onClickAddCartHandler(product.id)}
                                            >+</Button>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '1rem' }}>
                                        <Col style={{ backgroundColor: 'lightGrey', paddingBottom: Object.keys(currentCart).length && currentCart.products && currentCart.products.filter(productInCart => productInCart.id === product.id).length ? 10 : 0 }}>
                                            {Object.keys(currentCart).length && currentCart.products && currentCart.products.filter(productInCart => productInCart.id === product.id).length ?
                                                currentCart.products.filter(productInCart => productInCart.id === product.id).map(productInCart => {
                                                    return (
                                                        <>
                                                            <Row>
                                                                <Col xs={10}>
                                                                    <Link onClick={() => onClickEditItemHandler(productInCart.uniqId)}>
                                                                        <span>x{productInCart.quantity} </span>
                                                                        <span style={{ fontWeight: 'bolder', color: 'black' }}>{product.name}</span>
                                                                    </Link>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <span>€ {renderNewPrice(product.price, product.id, productInCart)} </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={1}>
                                                                    <Button className='buttonGeneralStyle' style={{ width: 30, padding: 1 }} onClick={() => onClickDeleteSameItemInCart(productInCart.uniqId)}>-</Button>
                                                                </Col>
                                                                <Col className='text-left'>
                                                                    <span>{renderListPreferencesIfExist(product.id, productInCart)}</span>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <Button className='buttonGeneralStyle' style={{ width: 30, padding: 1 }} onClick={() => onClickAddSameItemInCart(productInCart.uniqId)}>+</Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )
                                                })
                                                : null}
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })}
                    </Row>
                    : null}
            </>)
        })}
    </>)
}

export default MainPage;