import React, { useState } from 'react';
//---------- Bootstrap -----------------
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//---------- Components -----------------
import Logo from '../../../Assets/Images/logo.png';
//---------- Other import ----------------
import { Link, useLocation } from 'react-router-dom';
import classes from './TopNavbar.module.css';
import { useWindowWidth } from '@react-hook/window-size';
import { FaShoppingCart } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { SiFacebook } from 'react-icons/si';
import { AiOutlineInstagram } from 'react-icons/ai';
import { isLoaded, isEmpty } from 'react-redux-firebase'


const TopNavbar = () => {
    const screenWidth = useWindowWidth();
    const location = useLocation();
    const { auth, profile } = useSelector((state) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));
    const [currentCart] = useState(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {});

    return (<>
        <Navbar collapseOnSelect expand="lg" variant="light" className={classes.navStyle} >
            <Navbar.Brand as={Link} to="/" className={classes.imageNavStyle}>
                <Image className={classes.imageStyle} src={Logo} alt='Taporty' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className={"mr-auto text-left"}>
                </Nav>
                <Nav className={`${screenWidth > 720 ? 'text-right my-auto ' + classes.menuStyle : 'mr-auto text-left'}`}>
                    <Nav.Link as={Link} to={'/'}>
                        <span className={location.pathname === '/' ? classes.nameStyleSelected : classes.nameStyleNotSelected}>Home</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to={'/chi-siamo'}>
                        <span className={location.pathname === '/chi-siamo' ? classes.nameStyleSelected : classes.nameStyleNotSelected}>Chi siamo</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to={'/come-funziona'}>
                        <span className={location.pathname === '/come-funziona' ? classes.nameStyleSelected : classes.nameStyleNotSelected}>Come Funziona</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to={'/lavora-con-noi'}>
                        <span className={location.pathname === '/lavora-con-noi' ? classes.nameStyleSelected : classes.nameStyleNotSelected}>Lavora con noi</span>
                    </Nav.Link>
                    {
                        /*
                        <Nav.Link as="a" href="https://joinus.taporty.it/" target="_blank" rel="noreferrer">
                            <span className={classes.nameStyleNotSelected}>
                                Lavora con noi
                            </span>
                        </Nav.Link>
                        */
                    }

                    <Nav.Link as={Link} to={isLoaded(profile) && !isEmpty(profile) && isLoaded(auth) && !isEmpty(auth) ? '/profilo' : '/accedi'}>
                        <Row>
                            {isLoaded(profile) && !isEmpty(profile) && isLoaded(auth) && !isEmpty(auth) ?
                                <Col>
                                    <CgProfile size={22} color='#484848' />
                                </Col>
                                :
                                <Col className='my-auto'>
                                    <span className={location.pathname === '/accedi' ? classes.nameStyleSelected : classes.nameStyleNotSelected}>Accedi</span>
                                </Col>}
                        </Row>
                    </Nav.Link>
                    {isLoaded(profile) && isEmpty(profile) && isLoaded(auth) && isEmpty(auth) ?
                        <>
                            <Nav.Link as={Link} to={'/registrati'}>
                                <span className={location.pathname === '/registrati' ? classes.nameStyleSelected : classes.nameStyleNotSelected}>Registrati</span>
                            </Nav.Link>
                        </>
                        :
                        <Nav.Link as={Link} to={`${Object.keys(currentCart).length ? screenWidth < 1026 ? '/supplier/' + currentCart.supplier_id + '/carrello' : '/supplier/' + currentCart.supplier_id : '/'}`} >
                            <FaShoppingCart size={22} color='#484848' />
                        </Nav.Link>
                    }
                    <Nav.Link href={'https://www.facebook.com/TaportyDeliveryApp'} target='_blank'>
                        <SiFacebook color='black' />
                    </Nav.Link>
                    <Nav.Link href={'https://www.instagram.com/taporty_delivery_official/?hl=en'} target='_blank'>
                        <AiOutlineInstagram color='black' />
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </>)
}

export default TopNavbar;