import React, { useEffect } from 'react';
//-------------- Bootstrap ------------------
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
//-------- Other import -------------------
import { Link } from 'react-router-dom';
import ReactGA from "react-ga";
import Image401 from '../../Assets/Images/401.png';
import { Helmet } from "react-helmet";
import { FaHome } from 'react-icons/fa';
//--------------Style--------------------------------
import classes from './PageError.module.css';

const trackPage = (page: string) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

const Page401 = () => {

    useEffect(() => {
        localStorage.removeItem('token');
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>401</title>
                <meta name="description" content='Non Autorizzato' />
                <meta name="robots" content="noindex" />
            </Helmet>
            {trackPage('401')}
            <Container>
                <Row style={{ marginTop: '2rem' }}>
                    <Col className='text-left'>
                        <FaHome size={22} />
                        <Link to='/' style={{ paddingLeft: 10 }}>
                            <span className='colorRed'>Home</span>
                        </Link>
                    </Col>
                </Row>
                <Row >
                    <Col xs={{ span: 12 }}>
                        <h1 className='colorRed'>401 Non Sei Autorizzato</h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <span>Non sei autorizzato a visitare questa pagina, puoi tornare alla pagina home seguendo il link in alto a sinistra, o cambiare url di destinazione, grazie</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Image
                            className={classes.Image}
                            src={Image401}
                            alt='401-error-image-uva'
                        />
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default Page401
