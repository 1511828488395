import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//-------------- Components ----------------
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import classes from './ForgotPassword.module.css'
import { Link } from 'react-router-dom'
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

interface FormObject {
    email: string;
}



const ForgotPassword = () => {
    const [emailSuccess, setEmailSuccess] = useState<true | false>(false);
    const [emailError, setEmailError] = useState<true | false>(false);
    const [loadingApi, setLoadingApi] = useState<true | false>(false);

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const emailValidator = (value: string) => value.trim().match(/^.+@.+$/) || 'Email non valida';

    const onClickFormHandler = (data: FormObject) => {
        setEmailSuccess(true);
        /*clientCredentialApi.ResetPasswordEmail(data).then(respose => {
        }).catch(error => {
            setEmailError(true);
        })*/
    }

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Password dimenticata</title>
            <meta name="description" content='Password dimenticata' />
        </Helmet>
        <Container fluid style={{ paddingBottom: 40 }}>
            {emailSuccess === true ?
                <AlertGeneral closeAlone={true} description='Ti abbiamo mandato una email con le istruzioni per recuperare la password' color='success' setTrigger={setEmailSuccess} /> : null}
            <Row style={{ marginTop: '2rem' }}>
                <Col xl={{ span: 10, offset: 1 }}>
                    <Card className={classes.card} >
                        <Row><Col><span className={classes.titleStyle}>Password dimenticata</span></Col></Row>
                        <Row><Col><span className={classes.textStyle}>Inserisci la tua email nel form sottostante</span></Col></Row>
                        <Form onSubmit={handleSubmit(onClickFormHandler)} >
                            <Row><Col>
                                <Row style={{ marginTop: '2rem' }}>
                                    <Col xs={12} lg={{ span: 8, offset: 2 }}>
                                        <TextBox ref={register({ validate: emailValidator })} placeHolder={'Inserisci la tua email'} type={'email'} name={'email'}
                                            error={errors.email ? errors.email.message : ''}
                                            labelStyle={{ fontWeight: 'bolder' }}
                                            offset={0} span={12} labelName={'Email'}
                                        />
                                    </Col>
                                </Row>
                                {emailError ?
                                    <Row style={{ marginTop: 20 }}>
                                        <Col>
                                            <span className='errorStyle' >
                                                Email non trovata
                                        </span>
                                        </Col>
                                    </Row>
                                    : null}
                                <Row style={{ marginTop: '2.5rem' }}><Col>
                                    {loadingApi ? <LoadingButton />
                                        : <Button type='submit' className={'buttonGeneralStyle'} style={{ width: 150 }}>
                                            Continua
                                    </Button>
                                    }
                                </Col></Row>
                            </Col></Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>)
}


export default ForgotPassword