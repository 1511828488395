import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import {GiKnifeFork} from 'react-icons/gi';
import {BsChevronRight} from 'react-icons/bs';
import classes from './SupplierDetailPage.module.css';
import { useWindowWidth } from '@react-hook/window-size'; 

const SideMenu = ({supplier, onChangeCategoryChoosen, onResetMenuHandler}) => {
    const screenWidth = useWindowWidth();
    
    return(<>
        {screenWidth > 1025 ? 
        <>
            <Row>
                <Col>
                    <GiKnifeFork size={30} color={'#E22E2E'}/>
                    <span className={classes.categoryStyle}>Categorie</span>
                </Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col onClick={() => onResetMenuHandler()}>
                    <span className={classes.menuStyle}>Menu</span>
                </Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col>
                    {supplier.categories.map(category => {
                        return(
                            <Row key={category.id} style={{marginTop: 10}}>
                                <Col onClick={() => onChangeCategoryChoosen(category.id)}>
                                    <a>
                                    <span className={classes.categoryItemStyle}>
                                        {category.name}
                                    </span>
                                    </a>
                                    <BsChevronRight color={'black'} size={22}/>
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
            </Row>
        </>
        : <div className={classes.scrollingWrapper} style={{marginBottom: '3rem'}}>
            <div className={classes.cartItem}>
                <Button 
                    style={{width: 120}}
                    onClick={() => onResetMenuHandler() }
                    className={'buttonGeneralStyle'}
                    >Menu</Button>
            </div>
            {supplier.categories.map(category => {
            return(
                <div className={classes.cartItem} key={category.id}>
                    <Button style={{width: 120}}
                        className={'buttonGeneralStyle'}
                        onClick={() => onChangeCategoryChoosen(category.id) }
                    >{category.name}</Button>
                </div>
                )
            })}
        </div> 
        }
    </>)
}

export default SideMenu;