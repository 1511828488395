import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import ModalCheckAllergy from './ModalCheckAllergy';
import { Link } from 'react-router-dom'

const Header = ({ supplier, settings }) => {
    const [allergy, setAllergy] = useState(false);

    return (<>
        { allergy ?
            <ModalCheckAllergy setAllergy={setAllergy} phoneNumber={supplier.phoneNumber} />
            : null}
        <Row style={{ marginTop: '2rem' }}>
            <Col xs={12} md={4} className='text-right'>
                <Image src={supplier.imageUrl} style={{ width: '100%' }} />
            </Col>
            <Col className='text-left'>
                <Row>
                    <Col>
                        <h1>{supplier.name}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span>{supplier.address}</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col>
                        <span>{supplier.description}</span>
                    </Col>
                </Row>
                {supplier.minOrderAmount ?
                    <Row style={{ marginTop: 10 }}>
                        <Col><span style={{ fontWeight: 'bold' }}>MINIMO ORDINE: {supplier.minOrderAmount.toFixed(2)} €</span></Col>
                    </Row>
                    : null}
                {supplier.freeDeliveryThreshold ?
                    <Row style={{ marginTop: 10 }}>
                        <Col><span style={{ fontWeight: 'bold' }}>SPEDIZIONE GRATUITA SOPRA I {supplier.freeDeliveryThreshold.toFixed(2)} €</span></Col>
                    </Row>
                    : settings.freeDeliveryThreshold ?
                        <Row style={{ marginTop: 10 }}>
                            <Col><span style={{ fontWeight: 'bold' }}>SPEDIZIONE GRATUITA SOPRA I {settings.freeDeliveryThreshold.toFixed(2)} €</span></Col>
                        </Row>
                        : null}
                <Row style={{ marginTop: '1rem' }}>
                    <Col className='text-left'>
                        <BsFillExclamationTriangleFill size={28} color='red' />
                        <Link>
                            <span style={{ paddingLeft: 10, color: 'red' }} onClick={() => setAllergy(!allergy)}>Se hai un' allergia o un' intolleranza alimentare clicca qui.</span>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
}


export default Header;