
import { Moment } from "moment";
import Shift from "../models/shift";
import firebase from "firebase/app"
import BlockedShift from "../models/blocked_shift";
import { WeekdayMaxOrderCount } from "../models/weekday_max_order_count";
import ShiftOrderCount from "../models/shift_order_count";
import PhoneNumber from "../models/phone_number";
import DiscountCode from "../models/discount_code";
import Driver from "../models/driver";
import User from "../models/user";
import Order from "../models/order";
import OrderStates from "../models/order_states";
import moment from "moment";


class FirestoreService {
  private static _instance: FirestoreService;

  constructor() {
    this.firestore = firebase.firestore();
  }

  public static getInstance() {
    return this._instance || (this._instance = new this());
  }

  private firestore: firebase.firestore.Firestore;

  /// Restituisce i turni nell'area e nel range di orario specificati (inclusivo sia a destra che a sinistra)
  public async getShifts(areaId: string, startTime: firebase.firestore.Timestamp, endTime: firebase.firestore.Timestamp): Promise<Shift[]> {
    return (
      await this.firestore
        .collection("areas")
        .doc(areaId)
        .collection("shifts")
        .where('startTime', ">=", startTime)
        .where('startTime', "<=", endTime)
        .get()
    )
      .docs
      .map((snap) => snap.data() as Shift);
  }

  /// Restituisce le informazioni relative ai turni bloccati
  /// Vengono considerati solo i turni tra startTime e endTime (inclusivo sia a destra che a sinistra)
  public async getBlockedShifts(supplierId: string, startTime: firebase.firestore.Timestamp, endTime: firebase.firestore.Timestamp): Promise<BlockedShift[]> {
    return (
      await this.firestore
        .collection("suppliers")
        .doc(supplierId)
        .collection("blockedShifts")
        .where("startTime", ">=", startTime)
        .where("startTime", "<=", endTime)
        .get()
    )
      .docs
      .map((snap) => snap.data() as BlockedShift);
  }

  /// Restituisce le informazioni relative al numero massimo di ordini effettuabili per ciascun turno
  public async getMaxOrderCount(supplierId: string): Promise<WeekdayMaxOrderCount[]> {
    return (
      await this.firestore.collection("suppliers").doc(supplierId).collection("maxOrderCount").get()
    )
      .docs
      .map((snap) => snap.data() as WeekdayMaxOrderCount);
  }

  /// Restituisce le informazioni relative al numero totale di ordini effettuati per ciascun turno
  /// Vengono considerati solo i turni tra startTime e endTime (inclusivo sia a destra che a sinistra)
  public async getShiftOrderCounts(supplierId: string, startTime: firebase.firestore.Timestamp, endTime: firebase.firestore.Timestamp): Promise<ShiftOrderCount[]> {
    return (
      await this.firestore.
        collection("suppliers")
        .doc(supplierId)
        .collection("shiftOrderCounts")
        .where("startTime", ">=", startTime)
        .where("startTime", "<=", endTime)
        .get()
    )
      .docs
      .map((snap) => snap.data() as ShiftOrderCount);
  }

  public async checkPhoneNumberVerified(uid: string, phoneNumber: string): Promise<boolean> {
    let msisdn = parseInt("39" + phoneNumber.replaceAll(" ", ""));
    let snap = await this.firestore.collection("users").doc(uid).collection("phoneNumbers").doc(msisdn.toString()).get();
    if (!snap.exists) return false;
    let phoneNumberModel = snap.data() as PhoneNumber;
    return phoneNumberModel.verified;
  }

  public async getDiscountCodeById(id: string): Promise<DiscountCode> {
    return (await this.firestore.collection("discountCodes").doc(id).get()).data() as DiscountCode;
  }

  public async getDriverById(uid: string): Promise<Driver> {
    return (await this.firestore.collection("drivers").doc(uid).get()).data() as Driver;
  }

  public async getUserById(uid: string): Promise<User> {
    return (await this.firestore.collection("users").doc(uid).get()).data() as User;
  }

  public async createOrder(orderId: string, order: Order) {
    let ref = this.firestore.collection("orders").doc(orderId);
    await ref.set({
      ...order,
      reference: ref
    })
  }

  public async updateOrderState(orderId: string, state: OrderStates): Promise<boolean> {
    let timestampField: string;
    switch (state) {
      case OrderStates.CANCELLED:
        timestampField = "cancellationTimestamp";
        break;
      case OrderStates.ACCEPTED:
        timestampField = "acceptanceTimestamp";
        break;
      case OrderStates.REFUSED:
        timestampField = "refusalTimestamp";
        break;
      case OrderStates.PICKED_UP:
        timestampField = "pickupTimestamp";
        break;
      case OrderStates.DELIVERED:
        timestampField = "deliveryTimestamp";
        break;
      default:
        return false;
    }
    let document = this.firestore.collection("orders").doc(orderId);
    let error = false;
    await this.firestore.runTransaction(async (tx) => {
      let order = (await tx.get(document)).data() as Order | undefined;
      if (order === undefined) {
        error = true;
        return;
      }

      if (state === OrderStates.CANCELLED && order.state !== OrderStates.NEW) {
        error = true;
        return;
      }
      if (state === OrderStates.PICKED_UP && order.state === OrderStates.PICKED_UP) {
        return;
      }
      if (state === OrderStates.PICKED_UP && order.state !== OrderStates.READY) {
        error = true;
        return;
      }
      if (state === OrderStates.DELIVERED && order.state !== OrderStates.PICKED_UP) {
        error = true;
        return;
      }
      if ((state === OrderStates.ACCEPTED || state === OrderStates.REFUSED) && order.state !== OrderStates.NEW) {
        error = true;
        return;
      }
      tx.update(document, {
        state,
        visualized: false,
        replied: false,
        [timestampField]: firebase.firestore.Timestamp.fromMillis(moment().valueOf()),
      });
    });

    return !error;
  }

  public async createUser(uid: string, nominative: string, email: string) {
    await this.firestore.collection("users").doc(uid).set({
      nominative,
      email,
      notifyApp: true,
      uid,
    });
  }

  public async userExists(uid: string): Promise<boolean> {
    return (await this.firestore.collection("users").doc(uid).get()).exists;
  }


  public async getMaxOrderCountForWeekday(supplierId: string, weekday: number): Promise<WeekdayMaxOrderCount | undefined> {
    return (await this.firestore.collection("suppliers").doc(supplierId).collection("maxOrderCount").doc(weekday.toString()).get()).data() as WeekdayMaxOrderCount | undefined;
  }

  public async getShiftOrderCount(supplierId: string, shiftStartTime: firebase.firestore.Timestamp): Promise<number> {
    return ((await this.firestore.collection("suppliers").doc(supplierId).collection("shiftOrderCounts").doc(shiftStartTime.toMillis().toString()).get()).data() as ShiftOrderCount | undefined)?.count ?? 0;
  }

  public async checkIfShiftIsBlocked(supplierId: string, shiftStartTime: firebase.firestore.Timestamp): Promise<boolean> {
    return (await this.firestore.collection("suppliers").doc(supplierId).collection("blockedShifts").doc(shiftStartTime.toMillis().toString()).get()).exists;
  }
}

export default FirestoreService;