import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//----------------- Other import ----------------
import classes from './ProfilePage.module.css';

const SectionTitle = ({title}) => {
    return(<>
        <Row>
            <Col>
                <span className={classes.sectionTitle}>{title}</span>
            </Col>
        </Row>
    </>)   
}

export default SectionTitle;