import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Phones from '../../../Assets/Images/phones.png';
import Delivery from '../../../Assets/Images/delivery.png';
import classes from './WhoWeAre.module.css';
import bag from '../../../Assets/Animation/bag.json';
import map from '../../../Assets/Animation/map.json';
import Lottie from 'react-lottie';
import { useWindowWidth } from '@react-hook/window-size'; 

const WhoWeAre = () => {
    const screenWidth = useWindowWidth();

    let animation = map;
    let animation2 = bag;

    const defualtOptions = {
        loop:true,
        autoplay: true,
        animationData: animation,
        renderSettings: {
            preserveAspectRation: "xMidYmid slice"
        }
    }


    const defualtOptions2 = {
        loop:true,
        autoplay: true,
        animationData: animation2,
        renderSettings: {
            preserveAspectRation: "xMidYmid slice"
        }
    }

    return(<>
        <Container fluid>
            <Row style={{marginTop: 10}}>
                <Col xs={12} lg={6} style={{backgroundColor: '#E3E3E3'}}>
                    <Row style={{marginTop: '6rem'}}>
                        <Col className='text-left'>
                        <span className={classes.titleStyle}>Chi Siamo?</span>
                    </Col></Row>
                    <Row style={{marginTop: '1rem'}}>
                        <Col className='text-left' style={{padding: 20}}>
                        <p>Siamo giovani, giovani partiti da un’idea e con tanta voglia di portare Taporty in giro per l’Italia”</p>

                        <p>Il progetto “Taporty” nasce dall’esigenza di offrire un servizio di food delivery a tutti quei piccoli paesi e borghi non raggiunti dalle multinazionali del settore. 
                        Finalmente vediamo anche i piccoli paesi serviti come le grandi città!!!
                        Da qui è partita la nostra sfida…
                        Taporty è il servizio di Food Delivery 
                        non solo della tua città, ma del tuo paese e del tuo borgo.</p>

                        <p>L’idea di “Taporty” risale a circa 3 anni fa; il 10 Maggio 2020 parte il primo scooter a Priverno e finalmente nel Novembre 2020 nasce ufficialmente Taporty a Terracina. Da li a pochi mesi Taporty corre come un fulmine e consegna anche a Latina, Sant’Egidio alla Vibrata e a Roccagorga.</p>

                        <p>Il nostro sogno è quello di vedere i rider Taporty in giro per tutta Italia</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Image src={Phones} style={{width: '100%'}} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <Image src={Delivery} style={{width: '100%'}} />
                </Col>
                <Col style={{backgroundColor: '#E22E2E'}}>
                    <Row style={{marginTop: '2rem', marginBottom: '1rem'}}>
                        <Col>
                            <Row>
                                <Col lg={{span: 10, offset: 1}} className='text-left'>
                                    <span className={classes.titleSecondSection}>PRANZO E CENA A DOMICILIO</span>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '2rem'}}>
                                <Col lg={{span: 10, offset: 1}} className='text-left'>
                                    <span className={classes.descriptionSecondSection}>
                                    Servizio rapido di consegna a domicilio dai migliori Ristoranti. Tutto il cibo che vuoi è a portata di APP. 
                                    </span>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 30}}>
                                <Col>
                                    <Lottie options={defualtOptions} width={150} height={150} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row><Col lg={{span: 10, offset: 1}} className='text-left'>
                                <span className={classes.titleSecondSection}>FACILE VELOCE E GUSTOSO</span>
                            </Col></Row>
                            <Row style={{marginTop: '2rem'}}>
                                <Col lg={{span: 10, offset: 1}} className='text-left'>
                                    <span className={classes.descriptionSecondSection}>
                                    Unico requisito? Avere fame
                                    Non devi fare altro che scaricare l’APP
                                    scegliere il Ristorante, sfogliare il menu e ordinare!
                                    A portarti la cena calda ci pensa Taporty
                                    Made in Italy 100%, casa tua in soli 30 minuti.
                                    La nostra puntualità è garantita.
                                    A nessuno piace aspettare quando si ha tanta fame…
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Lottie options={defualtOptions2} width={200} height={150} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    
    
    </>)
}


export default WhoWeAre;