import moment from "moment";
import Shift from "../models/shift";

function getCustomerShiftString(shift: Shift): String {
  let start = moment(shift.startTime.toMillis());
  let end = moment(shift.endTime.toMillis());
  let diff = end.diff(start, "minutes");

  if (diff <= 15)
    start = moment(end).subtract(5, "minutes");
  else
    start = moment(end).subtract(10, "minutes");

  return start.format("HH:mm") + " - " + end.format("HH:mm");
}

export default getCustomerShiftString;