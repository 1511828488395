import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
//---------------- Components ------------------------
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//----------------- Other import -------------------
import { useForm } from 'react-hook-form';
import firebase from 'firebase/app';
import classes from './WorkWithUs.module.css';
import Driver from '../../../Assets/Images/imm-Rider.png';
import Fornitori from '../../../Assets/Images/Fornitore.png';
import Commercio from '../../../Assets/Images/Commercio.png';
import Area from '../../../models/area';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer/rootReducer';
import Select from '../../../Components/Commons/FormComponent/Select';
import { toastr } from 'react-redux-toastr';

type WorkWithUsData = {
    name: string;
    email: string;
    phoneNumber: string;
    address: string;
    areaId: string | null;
}

const initialData: WorkWithUsData = {
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    areaId: null,
};

const WorkWithUs = () => {
    useFirestoreConnect({ collection: "areas" });

    const [sendLoading, setSendLoading] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const areas = useSelector((state: RootState) => state.firestore.ordered.areas as Area[] | undefined);

    const {
        register: supplierRegister,
        handleSubmit: supplierHandleSubmit,
        errors: supplierErrors,
        clearErrors: supplierClearErrors,
        reset: supplierReset,
    } = useForm<WorkWithUsData>({ defaultValues: initialData });
    const {
        register: driverRegister,
        handleSubmit: driverHandleSubmit,
        errors: driverErrors,
        clearErrors: driverClearErrors,
        reset: driverReset,
    } = useForm<WorkWithUsData>({ defaultValues: initialData });

    const sendRequest = async (data: WorkWithUsData, type: "supplier" | "driver") => {
        console.log(data);
        if (type === "supplier")
            supplierClearErrors();
        else
            driverClearErrors();
        setSendLoading(true);
        const firestore = firebase.firestore();
        try {
            const ref = firestore.collection(type + "_work_with_us").doc();
            await ref.set({
                ...data,
                id: ref.id,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
            if (type === "supplier")
                supplierReset();
            else
                driverReset();
            toastr.success("Richiesta di contatto inviata con successo!", "");
        }
        catch (err) {
            toastr.error("Errore inaspettato durante l'invio della richiesta!", "");
            console.log(err);
        }
        finally {
            setSendLoading(false);
        }
    }

    const emailValidator = (value: string) => value.trim().match(/^.+@.+$/) || 'Email non valida';

    return (<>
        <Container fluid >
            <Row>
                <Col>
                    <Row style={{
                        backgroundImage: `url(${Driver})`, opacity: '90%', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center', backgroundSize: 'cover'
                    }}
                        className={classes.heigthCover}
                    >
                        <Col className='text-left' xs={12} md={6} lg={4}>
                            <Row style={{ marginTop: '1rem' }}><Col><span className={classes.youAre}>Sei un rider</span></Col></Row>
                            <Card style={{ padding: 20, marginTop: '0.2rem', marginBottom: '2rem' }}>
                                <Row><Col><span>Scrivici e inizia a consegnare in meno di 24 ore</span></Col></Row>
                                <Form onSubmit={driverHandleSubmit((data) => sendRequest(data, "driver"))} >
                                    <Row style={{ marginTop: '1rem' }}>
                                        <Col xs={12} lg={12}>
                                            <TextBox ref={driverRegister({ required: 'true' })} placeHolder={'Inserisci il tuo nome e cognome'} type={'input'} name={'name'}
                                                error={driverErrors.name ? 'Questo campo è obbligatorio' : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Nome e Cognome'}
                                            />
                                        </Col>
                                        <Col xs={12} lg={12} className="mt-2">
                                            <Select ref={driverRegister({ required: 'true' })} placeHolder={'Seleziona l\'area dove vorresti lavorare'} name={'areaId'}
                                                error={driverErrors.areaId ? 'Questo campo è obbligatorio' : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Area dove vorresti lavorare'}
                                                inputStyle={{ width: '100%' }} options={areas?.map((a) => ({ value: a.id, title: a.name })) ?? []}
                                            />
                                        </Col>
                                        <Col xs={12} lg={12} className="mt-2">
                                            <TextBox ref={driverRegister({ required: 'true' })} placeHolder={'Inserisci il tuo indirizzo'} type={'input'} name={'address'}
                                                error={driverErrors.address ? 'Questo campo è obbligatorio' : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Indirizzo'}
                                            />
                                        </Col>
                                        <Col xs={12} lg={12} className="mt-2">
                                            <TextBox ref={driverRegister({ validate: emailValidator })} placeHolder={'Inserisci la tua email'} type={'email'} name={'email'}
                                                error={driverErrors.email ? driverErrors.email.message : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Email'}
                                            />
                                        </Col>
                                        <Col xs={12} lg={12} className="mt-2">
                                            <TextBox ref={driverRegister({ required: 'true' })} placeHolder={'Inserisci il tuo numero di telefono'} type={'input'} name={'phoneNumber'}
                                                error={driverErrors.phoneNumber ? 'Questo campo è obbligatorio' : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Telefono'}
                                                inputStyle={{ width: '100%' }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className='text-center'>
                                            <Button disabled={areas === undefined || sendLoading} type='submit' className={'buttonGeneralStyle'} style={{ width: 200 }}>
                                                Contattaci
                                            </Button>
                                        </Col></Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{
                        backgroundImage: `url(${Fornitori})`, opacity: '90%', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center', backgroundSize: 'cover', marginTop: '2rem'
                    }}
                        className={classes.heigthCover}
                    >
                        <Col md={6} lg={8} className={classes.displayNoMobile}></Col>
                        <Col className='text-right' xs={12} md={6} lg={4}>
                            <Row style={{ marginTop: '1rem' }}>
                                <Col className='text-left'>
                                    <span className={classes.youAre}>Sei un fornitore</span>
                                </Col>
                            </Row>
                            <Card style={{ padding: 20, marginTop: '0.2rem', marginBottom: '2rem' }}>
                                <Row><Col className='text-left'><span>Scrivici e inizia a consegnare in meno di 24 ore</span></Col></Row>
                                <Form onSubmit={supplierHandleSubmit((data) => sendRequest(data, "supplier"))} >
                                    <Row style={{ marginTop: '2rem' }}>
                                        <Col xs={12} lg={12} className='text-left'>
                                            <TextBox ref={supplierRegister({ required: 'true' })} placeHolder={'Inserisci il nome della tua attività'} type={'input'} name={'name'}
                                                error={supplierErrors.name ? 'Questo campo è obbligatorio' : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Nome dell\'attività'}
                                            />
                                        </Col>
                                        <Col xs={12} lg={12} className="text-left mt-2">
                                            <Select ref={supplierRegister({ required: 'true' })} placeHolder={'Seleziona l\'area dove vorresti lavorare'} name={'areaId'}
                                                error={supplierErrors.areaId ? 'Questo campo è obbligatorio' : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Area dove vorresti lavorare'}
                                                inputStyle={{ width: '100%' }} options={areas?.map((a) => ({ value: a.id, title: a.name })) ?? []}
                                            />
                                        </Col>
                                        <Col xs={12} lg={12} className='text-left'>
                                            <TextBox ref={supplierRegister({ required: 'true' })} placeHolder={'Inserisci l\'indirizzo della tua attività'} type={'input'} name={'address'}
                                                error={supplierErrors.address ? 'Questo campo è obbligatorio' : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Indirizzo dell\'attività'}
                                            />
                                        </Col>
                                        <Col xs={12} lg={12} className='text-left mt-2'>
                                            <TextBox ref={supplierRegister({ validate: emailValidator })} placeHolder={'Inserisci la tua email'} type={'email'} name={'email'}
                                                error={supplierErrors.email ? supplierErrors.email.message : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Email'}
                                            />
                                        </Col>
                                        <Col xs={12} lg={12} className='text-left mt-2'>
                                            <TextBox ref={supplierRegister({ required: 'true' })} placeHolder={'Inserisci il tuo numero di telefono'} type={'input'} name={'phoneNumber'}
                                                error={supplierErrors.phoneNumber ? 'Questo campo è obbligatorio' : ''}
                                                labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                offset={0} span={12} labelName={'Telefono'}
                                                inputStyle={{ width: '100%' }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingLeft: 20 }} className="mt-2">
                                        <Col className='text-center'>
                                            <Button disabled={areas === undefined || sendLoading} type='submit' className={'buttonGeneralStyle'} style={{ width: 200 }}>
                                                Contattaci
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                    {
                        /*
                        <Row style={{backgroundImage: `url(${Commercio})`, opacity: '90%', backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center', backgroundSize: 'cover', marginTop: '2rem'}} 
                            className={classes.heigthCover}
                        >
                            <Col className='text-left' xs={12} md={6} lg={4}>
                                <Row style={{marginTop: '1rem'}}><Col><span className={classes.youAre}>Sei un commerciale</span></Col></Row>
                                <Card style={{padding: 20, marginTop: '0.2rem', marginBottom: '2rem'}}>
                                    <Row><Col><span>Scrivici e inzia a consegnare in meno di 24 ore</span></Col></Row>
                                    <Form onSubmit={handleSubmit(onSendFormContactHandler)} >
                                        <Row style={{ marginTop: '1rem' }}>
                                            <Col xs={12} lg={12}>
                                                <TextBox ref={register({ validate: emailValidator })} placeHolder={'Inserisci la tua email'} type={'email'} name={'email'}
                                                    error={errors.email ? errors.email.message : ''}
                                                    labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                    offset={0} span={12} labelName={'Email'}
                                                />
                                            </Col>
                                            <Col xs={12} lg={12}>
                                                <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci la tuo nome'} type={'input'} name={'name'}
                                                    error={errors.name ? 'Nome richiesto' : ''}
                                                    labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                    offset={0} span={12} labelName={'Nome  e Cognome'}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} lg={12}>
                                                <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci telefono'} type={'input'} name={'phoneNumber'}
                                                    error={errors.phoneNumber ? 'Telefono richiesto' : ''}
                                                    labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                                    offset={0} span={12} labelName={'Telefono'}
                                                    inputStyle={{width: '100%'}}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '2.5rem'}}>
                                            <Col className='text-center'>
                                            {loadingApi ?
                                                <LoadingButton />
                                                :
                                                <Button type='submit' className={'buttonGeneralStyle'} style={{ width: 200 }}>
                                                    Contattaci
                                                </Button>
                                            }
                                        </Col></Row>
                                    </Form>
                                </Card>        
                            </Col>
                        </Row>
                        */
                    }

                </Col>
            </Row>
        </Container>
    </>)
}


export default WorkWithUs;