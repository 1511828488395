import { useState, FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';
import classes from './ConfirmCartPage.module.css'
import { PaymentMethod } from '@stripe/stripe-js';

type ModalNewCardProps = {
    show: boolean,
    onClose: () => any,
    onCardConfirm: (paymentMethod: PaymentMethod) => any
}

const ModalNewCard: FunctionComponent<ModalNewCardProps> = ({ show, onClose, onCardConfirm }) => {
    const [confirmCardLoading, setConfirmCardLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {
        setHasError(false);
        setConfirmCardLoading(true);
        const { error, paymentMethod } = await stripe!.createPaymentMethod({
            type: 'card',
            card: elements!.getElement(CardElement)!,
        });

        setConfirmCardLoading(false);
        if (error === undefined && paymentMethod !== undefined)
            onCardConfirm(paymentMethod);
        else
            setHasError(true);
    };

    return (<>
        <Modal show={show} onHide={confirmCardLoading ? null : () => onClose()}>
            <Modal.Header closeButton>
                <Row>
                    <Col>
                        <h3 className={classes.modalTitile}>Nuova carta</h3>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body >
                <Row style={{ marginTop: '1rem' }}>
                    <Col className='text-center'>
                        <Form.Label className='formLabelStyle'><span>Dati Carta</span></Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={{ span: 12, offset: 0 }}>
                        <div style={{ border: '1px solid #ced4da', padding: 10 }}>
                            <CardElement options={{
                                style: {
                                    base: {
                                        color: "grey", fontFamily: 'Comfortaa', fontSmoothing: "antialiased",
                                        fontSize: "18px", "::placeholder": { color: "#aab7c4", },
                                    },
                                    invalid: { color: "#fa755a", iconColor: "#fa755a", },
                                },
                                hidePostalCode: true
                            }}
                            />
                        </div>
                    </Col>
                </Row>
                {hasError && <div className="mt-2" style={{ fontSize: "12px", color: "red" }}>Errore durante il salvataggio della carta. Ricontrolla i dati inseriti.</div>}
                <Row style={{ marginTop: '2rem' }}>
                    <Col className='text-center'>
                        {confirmCardLoading || elements === null || stripe === null ? <LoadingButton /> :
                            <Button className={"buttonGeneralStyle"}
                                type="button" onClick={handleSubmit} >
                                Conferma
                                    </Button>
                        }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    </>)
}

export default ModalNewCard;