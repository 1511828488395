import * as actionTypes from '../action/actionTypes'
import { updateObject } from '../utility';

const initializeState = {
    suppliers: [],
    supplierMissing: false,
}

const supplierReducer = (state = initializeState, action) => {
    switch (action.type) {
        case actionTypes.POPULATE_SUPPLIER:
            return updateObject(state, { suppliers: action.suppliers, supplierMissing: action.supplierMissing })
        case actionTypes.ERROR_API:
            return updateObject(state, { errorApi: true });
        case actionTypes.CLEAN_SUPPLIER_MISSING:
            return updateObject(state, { supplierMissing: false })
        default:
            return state;
    }
}

export default supplierReducer;