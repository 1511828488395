import moment from 'moment';
import Area from '../models/area';
import DiscountCode from '../models/discount_code';
import Settings from '../models/settings';
import Supplier from '../models/supplier';

export type AmountDetailsModel = {
  cartAmount: number;
  deliveryAmount: number;
  discountAmount: number;
  totalAmount: number;
  driverAmount: number;
  supplierPercentage: number;
  includesDiscount: boolean;
}

function getAmountDetails(
  cartAmount: number,
  code: DiscountCode | null | undefined,
  settings: Settings,
  area: Area,
  supplier: Supplier,
  distance: number | null = null,
  includeDiscount: boolean = true
): AmountDetailsModel {
  let discountAmount = getDiscountAmount(cartAmount, code, includeDiscount);
  let deliveryAmount = getDeliveryAmount(cartAmount, code, settings, area, supplier, distance, includeDiscount);
  let driverAmount = getDriverAmount(settings, area, supplier, distance);
  let supplierPercentage = getSupplierPercentage(supplier, settings);
  let totalAmount = cartAmount + deliveryAmount - discountAmount;
  return { cartAmount, deliveryAmount, discountAmount, totalAmount, driverAmount, supplierPercentage, includesDiscount: includeDiscount };
}

function getTotalToPay(
  cartAmount: number,
  code: DiscountCode | null | undefined,
  settings: Settings,
  area: Area,
  supplier: Supplier,
  distance: number | null = null,
  includeDiscount: boolean = true
): number {
  let discountAmount = getDiscountAmount(cartAmount, code, includeDiscount);
  let deliveryAmount = getDeliveryAmount(cartAmount, code, settings, area, supplier, distance, includeDiscount);
  return cartAmount + deliveryAmount - discountAmount;
}

function getDeliveryAmount(
  cartAmount: number,
  code: DiscountCode | null | undefined,
  settings: Settings,
  area: Area,
  supplier: Supplier,
  distance: number | null = null,
  includeDiscount: boolean = true
): number {
  if (supplier.useOwnDrivers == true) return supplier.deliveryAmountWithOwnDrivers!;

  let longDistanceThreshold = area?.longDistanceThreshold ?? settings?.longDistanceThreshold ?? 5.5;
  let longDistanceThreshold2 = area?.longDistanceThreshold2 ?? settings?.longDistanceThreshold2 ?? 6.5;

  let deliveryAmount: number;
  if (distance == null || distance <= longDistanceThreshold * 1000) {
    deliveryAmount = supplier?.deliveryAmount ?? area?.deliveryAmount ?? settings?.deliveryAmount ?? 2.9;
  } else if (distance <= longDistanceThreshold2 * 1000) {
    deliveryAmount = supplier?.longDistanceDeliveryAmount ?? area?.longDistanceDeliveryAmount ?? 3.5;
  } else {
    deliveryAmount = supplier?.longDistanceDeliveryAmount2 ?? area?.longDistanceDeliveryAmount2 ?? 4.5;
  }
  let freeDeliveryThreshold = supplier?.freeDeliveryThreshold ?? settings?.freeDeliveryThreshold ?? 50;

  if (includeDiscount && code !== null && code !== undefined && code.hasFreeDelivery) deliveryAmount = 0;
  if (cartAmount > freeDeliveryThreshold) deliveryAmount = 0;
  return deliveryAmount;
}

function getSupplierPercentage(supplier: Supplier, settings: Settings): number {
  let supplierPercentage: number | null | undefined;
  if (supplier.useOwnDrivers)
    supplierPercentage = supplier.supplierPercentageWithOwnDrivers;
  else if (supplier.hasPercentagePromo && moment().isBefore(moment(supplier.promoEndDate, "YYYY-MM-DD")))
    supplierPercentage = supplier.promoSupplierPercentage;
  else
    supplierPercentage = supplier.supplierPercentage;
  return supplierPercentage ?? settings.supplierPercentage;
}

function getDriverAmount(settings: Settings, area: Area, supplier: Supplier, distance: number | null = null) {
  if (supplier.useOwnDrivers === true)
    return 0;

  let longDistanceThreshold = area?.longDistanceThreshold ?? settings?.longDistanceThreshold ?? 5.5;
  let longDistanceThreshold2 = area?.longDistanceThreshold2 ?? settings?.longDistanceThreshold2 ?? 6.5;

  let driverAmount: number;
  if (distance == null || distance <= longDistanceThreshold * 1000) {
    driverAmount = area?.driverAmount ?? settings?.driverAmount ?? 2.9;
  } else if (distance <= longDistanceThreshold2 * 1000) {
    driverAmount = area?.longDistanceDriverAmount ?? settings?.longDistanceDriverAmount ?? 3.5;
  } else {
    driverAmount = area?.longDistanceDriverAmount2 ?? settings?.longDistanceDriverAmount2 ?? 3.7;
  }

  return driverAmount;
}

function getDiscountAmount(cartAmount: number, code: DiscountCode | null | undefined, includeDiscount: boolean = true): number {
  let discountAmount = 0;
  if (includeDiscount && code !== null && code !== undefined) {
    discountAmount = code.hasFixedAmount ? (Math.min(code.amount, cartAmount)) : (Math.round((cartAmount * code.percentage) * 100) / 100);
  }
  return discountAmount;
}

export { getAmountDetails, getDeliveryAmount, getDiscountAmount, getDriverAmount, getTotalToPay, }
