import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
//----------------- Other import -------------------
import Feature1 from '../../../Assets/Images/H-01.png';
import Feature2 from '../../../Assets/Images/H-02.png';
import Feature3 from '../../../Assets/Images/H-03.png';
import Feature4 from '../../../Assets/Images/Scooter.png';
import classes from './Home.module.css';
import delivery from '../../../Assets/Animation/delivery.json';
import Lottie from 'react-lottie';
import { useWindowWidth } from '@react-hook/window-size'; 

const Features = () => {
    const screenWidth = useWindowWidth();
    const [textFeatures, setTextFeatures] = useState([{id: 1, title: "Scarica l'APP", description: 'Pizza o giapponese? Nessun problema, scegli il ristorante che più ti piace.', image: Feature1},
    {id: 2, title: 'Inserisci il tuo indirizzo', description: 'Hai trovato quello che cercavi? Aggiungi i prodotti al carrello ed ordina ora.', image: Feature2},
    {id: 3, title: 'Ordina dal tuo ristorante preferito', description: 'Non ti resta altro che aspettare il nostro corriere e goderti il tuo pranzo/cena.', image: Feature3},
    {id: 4, title: "Taporty arrivera' a casa tua", description: 'Non ti resta altro che aspettare il nostro corriere e goderti il tuo pranzo/cena.'}]);

    let animation = delivery;

    const defualtOptions = {
        loop:true,
        autoplay: true,
        animationData: animation,
        renderSettings: {
            preserveAspectRation: "xMidYmid slice"
        }
    }


    return(
        
        <>
            <Row style={{marginTop: '2rem'}}>
                <Col xs={{span: 10, offset: 1}}>
                    <Row>
                        {textFeatures.map( feature => {
                            return(
                                <Col xs={12} md={6} lg={3} key={feature.id}>
                                    <Row >
                                        <Col>
                                            {feature.id === 4 ? 
                                                <Lottie options={defualtOptions} height={screenWidth > 1025 ? 300 : 260} width={200} />
                                            :
                                                <Image src={feature.image} style={{width: '80%'}}/>
                                            }
                                        </Col>
                                        <Col xs={12} lg={3} key={feature.id} className={`text-center ${classes.noShowDesktop}`} style={{zIndex: 88}}>
                                            <div style={{ padding: 2, width: 35, backgroundColor: '#EB4D4D', borderRadius: '100%', marginLeft: 'auto', marginRight: 'auto', }} 
                                                className='text-center'>
                                                    <span className={classes.featureNumber} >
                                                        {feature.id}
                                                    </span>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={3} key={feature.id} className={classes.noShowDesktop}
                                            style={{border: '2px solid red', borderRadius: 20, marginTop: 10, marginTop: '-0.4rem'}}
                                        >
                                            <span className={classes.featureName}>
                                                {feature.title}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: '-4rem'}} className={classes.noShowMobile}>
                <Col xs={{span: 10, offset: 1}}>
                    <Row>
                        {textFeatures.map( feature => {
                            return(<>
                                <Col xs={12} lg={3} key={feature.id} className='text-center' style={{zIndex: 88}}>
                                    <div style={{ padding: 2, width: 35, backgroundColor: '#EB4D4D', borderRadius: '100%', marginLeft: 'auto', marginRight: 'auto', }} 
                                        className='text-center'>
                                            <span className={classes.featureNumber} >
                                                {feature.id}
                                            </span>
                                    </div>
                                </Col>
                            </>)
                        })}
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: '-1rem'}} className={classes.noShowMobile}>
                <Col xs={{span: 10, offset: 1}} style={{border: '2px solid red', borderRadius: 20, marginTop: 10}}>
                    <Row>
                        {textFeatures.map( feature => {
                            return(<>
                                <Col xs={12} lg={3} key={feature.id} style={{padding: 5}}>
                                    <span className={classes.featureName}>
                                        {feature.title}
                                    </span>
                                </Col>
                            </>)
                        })}
                    </Row>
                </Col>
            </Row>
        </>
    )
}


export default Features;