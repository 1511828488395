import React, { FunctionComponent, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//------------------ Components import ---------------------
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';
import TextBox from '../../Commons/FormComponent/TextBox';
//-------------------- Other import ---------------------
import { useForm } from 'react-hook-form';
import Datetime from 'react-datetime';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer/rootReducer';
import { Moment } from 'moment';
import moment from 'moment';
import Shift from '../../../models/shift';
import getCustomerShiftString from '../../../functions/get_customer_shift_string';

export type Step1FormData = {
    email: string,
    nominative: string,
    phoneNumber: string,
    streetNumber: string,
    driverNotes: string,
    address: string,
}

type Step1Props = {
    date: Moment,
    onDateChange: (date: Moment) => any,
    shiftHasError: boolean,
    selectedShift: Shift | null,
    availableShifts: Shift[] | null,
    shiftLoading: boolean;
    onConfirmFirstStepCart: (data: Step1FormData) => any,
    onShiftChange: (shift: Shift) => any,
    nextStepLoading: boolean;
    updateAvailableShifts: () => any;
    addressError: string | null;
}

const Step1: FunctionComponent<Step1Props> = ({ onConfirmFirstStepCart, onDateChange, date, availableShifts,
    onShiftChange, shiftHasError, nextStepLoading, selectedShift, shiftLoading, updateAvailableShifts, addressError }) => {
    const { auth, profile } = useSelector((state: RootState) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));

    const { register, handleSubmit, errors, reset } = useForm<Step1FormData>({
        defaultValues: {
            email: '',
            nominative: '',
            phoneNumber: '',
            streetNumber: '',
            address: localStorage.getItem('address') !== null ? JSON.parse(localStorage.getItem('address')!).address : '',
            driverNotes: '',
        }
    }); // initialise the hook

    useEffect(() => {
        if (isLoaded(auth) && isLoaded(profile) && !isEmpty(auth) && !isEmpty(profile)) {
            reset({
                email: auth.email ?? "",
                phoneNumber: profile.phoneNumber,
                nominative: profile.nominative,
                address: localStorage.getItem('address') !== null ? JSON.parse(localStorage.getItem('address')!).address : '',
                streetNumber: "",
            })
        }
    }, [profile, auth]);

    let filteredShifts: Shift[] = [];
    if (!shiftLoading && availableShifts !== null) {
        filteredShifts = availableShifts.filter((s) => {
            let datetime = moment(s.startTime.toMillis());
            return date.isSame(datetime, "day");
        });
    }

    return (<>
        <Form onSubmit={handleSubmit(onConfirmFirstStepCart)} >
            <Row style={{ marginTop: '1rem' }}>
                <Col>
                    <span style={{ color: '#E22E2E', fontSize: 18 }}>Informazioni consegna</span>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} style={{ marginTop: '1rem' }}>
                    <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci il tuo nome'} type={'nominative'} name={'nominative'}
                        error={errors.nominative ? 'Campo richiesto' : ''}
                        labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                        offset={0} span={12} labelName={'Nome'}
                    />
                </Col>
                <Col xs={12} lg={6} style={{ marginTop: '1rem' }}>
                    <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci il tuo numero di telefono'} type={'phoneNumber'} name={'phoneNumber'}
                        error={errors.phoneNumber ? 'Campo Richiesto' : ''}
                        labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                        offset={0} span={12} labelName={'Numero di telefono'}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <Col className='my-auto'>
                    <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci indirizzo'} type={'input'} name={'address'}
                        error={errors.address ? 'Campo Richiesto' : (addressError !== null ? addressError : '')}
                        labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                        offset={0} span={12} labelName={'Indirizzo'}
                    />
                </Col>
                <Col xs={12} lg={6} style={{ marginTop: '1rem' }}>
                    <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci numero civico / citofono'} type={'input'} name={'streetNumber'}
                        error={errors.streetNumber ? 'Campo Richiesto' : ''}
                        labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                        offset={0} span={12} labelName={'Citofono / Civico'}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <Col>
                    <span style={{ color: '#E22E2E', fontSize: 18 }}>Orario Consegna</span>
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
                {
                    shiftLoading &&
                    <LoadingButton />
                }
                {
                    !shiftLoading && <>
                        <Col xs={12} lg={{ span: 6 }}>
                            <Datetime timeFormat={false} onChange={(event) => onDateChange(moment(event))}
                                value={date} dateFormat={'dddd, D MMMM Y'} inputProps={{ className: 'dateTimeStyle' }}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            {filteredShifts.length > 0 ?
                                <Form.Control as="select" value={selectedShift !== null ? selectedShift.startTime.toMillis() : -1}
                                    onChange={(e) => onShiftChange(filteredShifts.find((s) => s.startTime.toMillis() === parseInt(e.target.value))!)}
                                >
                                    <option disabled value={-1} key={-1}>Seleziona orario di consegna</option>
                                    {filteredShifts.map(shift => {
                                        return (
                                            <option
                                                key={shift.startTime.toMillis()}
                                                value={shift.startTime.toMillis()}
                                            >
                                                {getCustomerShiftString(shift)}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                                : <span>Non ci sono orari disponibili per la consegna</span>}
                            {shiftHasError ?
                                <span className='text-danger'>Seleziona un orario di consegna</span>
                                : null}
                        </Col>
                        <Col className="mt-2">
                            <div className="linkButton" onClick={updateAvailableShifts}>Aggiorna turni disponibili</div>
                        </Col>
                    </>
                }
            </Row>

            <Row style={{ marginTop: '1rem' }}>
                <Col>
                    <span style={{ color: '#E22E2E', fontSize: 18 }}>Note fattorino</span>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12} style={{ marginTop: '1rem' }}>
                    <TextBox ref={register({ required: false })} placeHolder={'Inserisci un messaggio per il fattorino'} type={'textarea'} name={'driverNotes'}
                        textarea inputStyle={{ height: 200 }}
                        labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                        offset={2} span={8} labelName={'Note'}
                    />
                </Col>
            </Row>

            <Row style={{ marginTop: '2.5rem', paddingLeft: 20, marginBottom: '2rem' }}><Col>
                {nextStepLoading ?
                    <LoadingButton />
                    :
                    <Button type='submit' className={'buttonGeneralStyle'} style={{ width: 200 }}>
                        Continua
                    </Button>
                }
            </Col></Row>
        </Form>

    </>)
}


export default Step1;