import React, { useState, useEffect, FunctionComponent } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
//---------------------- Components -----------------
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import Header from '../../../Components/PublicPages/SupplierDetailPage/Header';
import SideMenu from '../../../Components/PublicPages/SupplierDetailPage/SideMenu';
import MainPage from '../../../Components/PublicPages/SupplierDetailPage/MainPage';
import ModalPreference from '../../../Components/PublicPages/SupplierDetailPage/ModalPreference';
import ModalCartFull from '../../../Components/PublicPages/SupplierDetailPage/ModalCartFull';
import CartPage from '../../../Components/PublicPages/SupplierDetailPage/CartPage';
import ModalRestaurantNotInArea from '../../../Components/PublicPages/SupplierDetailPage/ModalRestaurantNotInArea';
//--------------------- Other import ---------------
import { useHistory, useParams } from 'react-router';
import { useFirebase, useFirestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import classes from './SupplierDetailPage.module.css';
import * as geofirestore from 'geofirestore';
import { useWindowWidth } from '@react-hook/window-size';

const SupplierDetailPage = () => {
    const { supplierId } = useParams();
    const { carrello } = useParams();
    const history = useHistory();
    const firebase = useFirebase();
    const firestore = firebase.firestore();
    const GeoFirestore = geofirestore.initializeApp(firestore);
    useFirestoreConnect({ collection: "settings", doc: "settings", storeAs: "settings" });
    const settings = useSelector(state => state.firestore.data.settings);
    const geocollection = GeoFirestore.collection('suppliers');
    const [address, setAddress] = useState(localStorage.getItem('address') ? { ...JSON.parse(localStorage.getItem('address')) } : null);
    useFirestoreConnect({ collection: "suppliers", doc: supplierId });
    useFirestoreConnect({ collection: `suppliers/${supplierId}/products` });
    useFirestoreConnect({ collection: `suppliers/${supplierId}/preferenceLists` });
    const supplier = useSelector(state => state.firestore.ordered['suppliers']);
    const products = useSelector(state => state.firestore.ordered[`suppliers/${supplierId}/products`]);
    const preferenceList = useSelector(state => state.firestore.ordered[`suppliers/${supplierId}/preferenceLists`]);
    const { auth, profile } = useSelector((state) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));
    const [menu, setMenu] = useState([]);
    const [menuFiltered, setMenuFiltered] = useState([]);
    const [hasPreference, setHasPreference] = useState([]);
    const [choosenProduct, setChoosenProduct] = useState('');
    const [choosenPreference, setChoosenPreference] = useState([]);

    const [currentCart, setCurrentCart] = useState(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {});
    const [cartIsAlreadyFull, setCartIsAlreadyFull] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [restaurantNotInArea, setRestaurantNotInArea] = useState(false);
    const screenWidth = useWindowWidth();

    //deliveryAmount ( prezzo delivery) , senno in settings
    //freeDeliveryThreshold // in supplier
    //minOrderAmount


    useEffect(() => {
        let yourCart = JSON.parse(localStorage.getItem('cart'));
        if (yourCart && Object.keys(yourCart).length && yourCart.supplier_id && yourCart.supplier_id !== supplierId) {
            setCartIsAlreadyFull(true)
        }
    }, []);

    useEffect(() => {
        if (isLoaded(supplier) && !isEmpty(supplier) && isLoaded(products) && !isEmpty(products)) {
            const newMenu = [];
            supplier[0].categories.forEach(category => {
                const filteredProducts = products.filter(product => product.category === category.id);
                newMenu.push({ categoryId: category.id, categoryName: category.name, isVisible: true, products: filteredProducts });
            })


            setMenu(newMenu);
        }

    }, [supplier, products]);

    useEffect(() => {
        if (!isEmpty(settings) && isLoaded(settings)) {
            const geoQuery = geocollection.near({
                center: new firebase.firestore.GeoPoint(address.latitude, address.longitude),
                radius: settings.supplierRadius + 5,
            }).where('hidden', '==', false).where("zipCodes", 'array-contains', address.zipCode);

            geoQuery.get().then((value) => {
                let isRestaurantInArea = false;
                value.forEach(value => {
                    if (value.data().reference.id === supplierId) {
                        isRestaurantInArea = true;
                    }
                });

                if (!isRestaurantInArea) {
                    setRestaurantNotInArea(true);
                }
            });


        }
    }, [settings]);

    const emptyCartHandler = () => {
        localStorage.setItem('cart', JSON.stringify({}));
        setCurrentCart({});
        setCartIsAlreadyFull(false);
    }

    const onChangeVisibilityCategoryHandler = (categoryId) => {
        const newMenu = menu.map(category => category.categoryId === categoryId ? { ...category, isVisible: !category.isVisible } : category)
        setMenu(newMenu);
    }

    const onChangeCategoryChoosen = (categoryId) => {
        const newMenu = [];
        const findCategory = menu.find(category => category.categoryId === categoryId);
        newMenu.push({ ...findCategory, isVisible: true })
        setMenuFiltered(newMenu);
    }

    const onResetMenuHandler = () => {
        setMenuFiltered([]);
    }

    const onClickAddCartHandler = (productId) => {
        checkIfUserIsLogedIn();
        let preferenceFound = [];
        preferenceList.forEach(preference => {
            if (preference.products && preference.products.find(product => product === productId)) {
                preferenceFound.push(preference);
            }
        });


        setHasPreference(preferenceFound);
        setChoosenProduct(productId);
    }

    const onHideModalPreference = () => {
        setHasPreference([]);
        setChoosenPreference([]);
        setChoosenProduct(null);
    }

    const checkIfUserIsLogedIn = () => {
        if (isLoaded(profile) && isEmpty(profile) && isLoaded(auth) && isEmpty(auth)) {
            history.push('/accedi');
        }
    }

    const onChoosePreferenceHandler = (preferenceId) => {
        let foundPreference = null;
        hasPreference.forEach(prefList => {
            if (!foundPreference) {
                foundPreference = prefList.preferences.find(preference => preference.id === preferenceId);
            }
        });

        if (foundPreference) {
            setChoosenPreference(choosenPreference.concat(foundPreference));
        }
    }

    const onCreatePreferenceTextHandler = (text) => {
        if (text) {
            if (choosenPreference.find(preference => preference.type === 'text')) {
                setChoosenPreference(choosenPreference.map(preference => preference.type === 'text' ? { ...preference, id: text, name: text } : preference))
            } else {
                setChoosenPreference(choosenPreference.concat({ id: text, name: text, type: 'text' }));
            }
        } else {
            const newChoosenPreference = [...choosenPreference];
            const index = choosenPreference.findIndex(preference => preference.type === 'text');
            newChoosenPreference.splice(index, 1);
            setChoosenPreference(newChoosenPreference);
        }
    }

    const onDeletePreferenceHandler = (preferenceId) => {
        let newChoosenPreference = [...choosenPreference];
        const index = newChoosenPreference.findIndex(preference => preference.id === preferenceId);
        newChoosenPreference.splice(index, 1);

        setChoosenPreference(newChoosenPreference);
    }

    const onConfirmProductAndPreferenceHandler = () => {
        let localItemInCart = JSON.parse(localStorage.getItem('cart'));
        if (!localItemInCart || (localItemInCart && !Object.keys(localItemInCart).length)) {
            localItemInCart = { supplier_id: supplierId, products: [] };
        }


        let quantity = 1;
        if (isEdit) {
            const editedProductFound = localItemInCart.products.find(product => product.uniqId === isEdit);
            if (editedProductFound) {
                quantity = editedProductFound.quantity;
                const editedIndex = localItemInCart.products.findIndex(product => product.uniqId === isEdit);
                localItemInCart.products.splice(editedIndex, 1);
                localItemInCart.products = localItemInCart.products.map((product, index) => {
                    return {
                        ...product,
                        uniqId: index + 1,
                    }
                })
            }
        }

        console.log('choosenPreference',choosenPreference);
        const foundProducts = localItemInCart.products.filter(product => product.id === choosenProduct); //Trova tutti i prodotti
        console.log('found Products',foundProducts);
        console.log('has preference',hasPreference);
        let foundProduct = null;
        //Dato un prodotto id, devo trovare tra tutti i prodotti che ho nel carrello se ce ne sta uno che metcha con le reference del carrello
        foundProducts.forEach(product => {
            let isThisAPorductWithSameReference = false;
            let countSupport = 0;
            if (product.preferences.length === choosenPreference.length) {
                hasPreference && hasPreference.forEach(preferences => {
                    preferences.preferences.forEach(preference => {
                        if (product.preferences.find(listPref => listPref.id === preference.id)
                            && choosenPreference.find(choosenPref => choosenPref.id === preference.id)) {
                            countSupport++;
                        }

                    })
                })
            }
            if (choosenPreference.find(choosenPref => choosenPref.type) && product.preferences.find(listPref => listPref.type)
            && choosenPreference.find(choosenPref => choosenPref.type).name === product.preferences.find(listPref => listPref.type).name) {
                if (countSupport === choosenPreference.length || countSupport === choosenPreference.length - 1 || countSupport - 1 === choosenPreference.length) {
                    isThisAPorductWithSameReference = true;
                }
            }
            if (!choosenPreference.find(choosenPref => choosenPref.type) && !product.preferences.find(listPref => listPref.type)) {
                if (countSupport === choosenPreference.length){
                    isThisAPorductWithSameReference = true;
                }
            }
            /*if (countSupport === choosenPreference.length || countSupport === choosenPreference.length - 1 || countSupport - 1 === choosenPreference.length) {
                if (choosenPreference.find(choosenPref => choosenPref.type) && product.preferences.find(listPref => listPref.type)
                    && choosenPreference.find(choosenPref => choosenPref.type).name === product.preferences.find(listPref => listPref.type).name) {
                    isThisAPorductWithSameReference = true;
                }
                if (!choosenPreference.find(choosenPref => choosenPref.type) && !product.preferences.find(listPref => listPref.type)) {
                    isThisAPorductWithSameReference = true;
                }
            }*/

            if (isThisAPorductWithSameReference) {
                foundProduct = product;
                localItemInCart.products = localItemInCart.products.map(cartProduct => cartProduct.uniqId === foundProduct.uniqId ? { ...cartProduct, quantity: cartProduct.quantity + quantity } : cartProduct)
            }
        })

        if (!foundProduct) { //Se il prodotto e' uguale e le preferenze sono uguali allora aggiungo quantita'
            localItemInCart.products.push({ uniqId: localItemInCart.products.length + 1, id: choosenProduct, quantity: quantity, preferences: choosenPreference });
        }
        localStorage.setItem('cart', JSON.stringify(localItemInCart));
        setCurrentCart(localItemInCart);
        setHasPreference([]);
        setChoosenPreference([]);
        setChoosenProduct('');
        setIsEdit(false);

    }

    const onClickDeleteSameItemInCart = (uniqId) => {
        checkIfUserIsLogedIn();
        let localItemInCart = JSON.parse(localStorage.getItem('cart'));
        const foundProduct = localItemInCart.products.find(product => product.uniqId === uniqId);
        if (foundProduct) {
            if (foundProduct.quantity === 1) {
                const index = localItemInCart.products.findIndex(product => product.uniqId === uniqId);
                localItemInCart.products.splice(index, 1);
                localItemInCart.products = localItemInCart.products.map((product, index) => {
                    return {
                        ...product,
                        uniqId: index + 1,
                    }
                })
            } else {
                localItemInCart.products = localItemInCart.products.map(product => product.uniqId === uniqId ? { ...product, quantity: product.quantity - 1 } : product)
            }
        }


        setCurrentCart(localItemInCart);
        localStorage.setItem('cart', JSON.stringify(localItemInCart));

    }

    const onClickAddSameItemInCart = (uniqId) => {
        checkIfUserIsLogedIn();
        let localItemInCart = JSON.parse(localStorage.getItem('cart'));
        if (localItemInCart.products.find(product => product.uniqId === uniqId)) {
            localItemInCart.products = localItemInCart.products.map(product => product.uniqId === uniqId ? { ...product, quantity: product.quantity + 1 } : product)
        }

        setCurrentCart(localItemInCart);
        localStorage.setItem('cart', JSON.stringify(localItemInCart));
    }

    const onClickEditItemHandler = (uniqId) => {
        checkIfUserIsLogedIn();
        let localItemInCart = JSON.parse(localStorage.getItem('cart'));
        const foundProducts = localItemInCart.products.find(product => product.uniqId === uniqId);
        console.log(foundProducts);
        let preferenceFound = [];
        preferenceList.forEach(preference => {
            console.log(preference);
            if (preference.products.find(product => product === foundProducts.id)) {
                preferenceFound.push(preference);
            }
        });

        console.log(preferenceFound);

        setHasPreference(preferenceFound);
        setChoosenPreference(foundProducts.preferences);
        setIsEdit(uniqId);
        setChoosenProduct(foundProducts.id);
    }

    const onClickOrderNowHandler = () => {
        if (isLoaded(profile) && isEmpty(profile) && isLoaded(auth) && isEmpty(auth)) {
            history.push('/accedi');
        } else {
            history.push('/conferma-carrello');
        }
    }

    const isButtonDisabled = () => {
        if (supplier.minOrderAmount > renderTotalPrice(false)) {
            return true;
        }

        return false;
    }

    const renderTotalPrice = (isPriceWithDelivery) => {
        let totalPrice = 0;
        currentCart.products && currentCart.products.forEach(productInCart => {
            let foundProduct = products.find(product => product.id === productInCart.id);
            if (foundProduct) {
                let priceWithExtra = foundProduct.price;
                productInCart.preferences && productInCart.preferences.forEach(preference => {
                    if (preference.price) {
                        priceWithExtra += preference.price;
                    }
                })
                totalPrice += priceWithExtra * productInCart.quantity;
            }
        })

        let maxOrderAmount = settings.freeDeliveryThreshold;
        if (supplier.freeDeliveryThreshold) {
            maxOrderAmount = supplier.freeDeliveryThreshold;
        }

        if (isPriceWithDelivery && totalPrice < maxOrderAmount) {
            totalPrice += settings.deliveryAmount;
        }

        return totalPrice;
    }

    console.log(carrello);

    return (<>

        {restaurantNotInArea ?
            <ModalRestaurantNotInArea />
            : null}
        {cartIsAlreadyFull ?
            <ModalCartFull emptyCartHandler={emptyCartHandler} />
            : null}
        {choosenProduct ?
            <ModalPreference hasPreference={hasPreference} onHideModalPreference={onHideModalPreference}
                choosenProduct={choosenProduct} products={products}
                choosenPreference={choosenPreference} onConfirmProductAndPreferenceHandler={onConfirmProductAndPreferenceHandler}
                onDeletePreferenceHandler={onDeletePreferenceHandler} onChoosePreferenceHandler={onChoosePreferenceHandler}
                onCreatePreferenceTextHandler={onCreatePreferenceTextHandler} isEdit={isEdit}
            />
            : null}

        <Container fluid>
            {isLoaded(supplier) && !isEmpty(supplier) && isLoaded(products) && !isEmpty(products) && menu.length ?
                <>
                    <Header supplier={supplier[0]} settings={settings} />
                    <Row style={{ marginTop: '4rem' }}>
                        {(!carrello && screenWidth < 1026) || screenWidth > 1025 ?
                            <>
                                <Col xs={12} lg={3} className={classes.styleMenu}>
                                    <SideMenu supplier={supplier[0]} onChangeCategoryChoosen={onChangeCategoryChoosen}
                                        onResetMenuHandler={onResetMenuHandler}
                                    />
                                </Col>
                                <Col xs={12} lg={5} className={classes.styleMenu}>
                                    <MainPage menu={menu} onChangeVisibilityCategoryHandler={onChangeVisibilityCategoryHandler}
                                        menuFiltered={menuFiltered} onClickAddCartHandler={onClickAddCartHandler}
                                        currentCart={currentCart} onClickDeleteSameItemInCart={onClickDeleteSameItemInCart}
                                        onClickAddSameItemInCart={onClickAddSameItemInCart} onClickEditItemHandler={onClickEditItemHandler}
                                    />
                                </Col>
                            </>
                            : null}
                        <Col xs={12} lg={4}>
                            {!cartIsAlreadyFull ?
                                screenWidth > 1025 || (carrello && screenWidth < 1026) ?
                                    <CartPage supplier={supplier[0]} settings={settings} products={products}
                                        currentCart={currentCart} onClickDeleteSameItemInCart={onClickDeleteSameItemInCart}
                                        onClickAddSameItemInCart={onClickAddSameItemInCart} onClickEditItemHandler={onClickEditItemHandler}
                                        onClickOrderNowHandler={onClickOrderNowHandler}
                                    />
                                    : null
                                : null}
                        </Col>
                    </Row>
                    {screenWidth < 1026 && !carrello ?
                        <div className={classes.stickyContainer}>
                            <Row style={{ marginTop: 20 }}>
                                <Col>
                                    <Button className='buttonGeneralStyle' disabled={isButtonDisabled()}
                                        onClick={() => history.push('/supplier/' + supplierId + '/carrello')}>
                                        Carrello
                                </Button>
                                </Col>
                            </Row>
                        </div>
                        : null}
                </>
                : <LoadingButton />}
        </Container>
    </>)
}

export default SupplierDetailPage;