import moment from "moment";
import { Moment } from "moment";
import GeohashPoint from "./geohash_point";
import Holiday from "./holiday";
import ProductCategory from "./product_category";
import Timetable from "./timetable";
import firebase from 'firebase/app'
import { WeekdayNumber } from "./discount_code";
import Area from "./area";

type Supplier = {
  reference: firebase.firestore.DocumentReference;
  name: string;
  description: string;
  imageUrl: string;
  thumbnailUrl: string;
  phoneNumber: string;

  category: string;
  tags: string[];

  geohashPoint: GeohashPoint;
  address: string;

  holidays: Holiday[];
  weekdayTimetable: {
    1: Timetable,
    2: Timetable,
    3: Timetable,
    4: Timetable,
    5: Timetable,
    6: Timetable,
    7: Timetable,
  };

  rating?: number;
  ratingCounts?: {
    1: number,
    2: number,
    3: number,
    4: number,
    5: number,
  };

  isDisabled: boolean;

  categories: ProductCategory[];

  supplierPercentage: number;
  useOwnDrivers: boolean | undefined;
  supplierPercentageWithOwnDrivers: number | undefined | null;
  deliveryAmountWithOwnDrivers: number | undefined | null;
  deliveryAmount: number;
  longDistanceDeliveryAmount: number;
  longDistanceDeliveryAmount2: number;
  freeDeliveryThreshold: number;

  hasPercentagePromo: boolean | undefined;
  promoSupplierPercentage: number | undefined;
  promoEndDate: string | undefined;

  areaId: string;
  shiftLength: number | undefined;

  minOrderTime: number;
  minOrderAmount: number | undefined;

  area?: Area;
  responsibleForCash: boolean | undefined;
}

export type WeekdaysShiftStartTimes = {
  1: Moment[],
  2: Moment[],
  3: Moment[],
  4: Moment[],
  5: Moment[],
  6: Moment[],
  7: Moment[],
}

class WeekdayTime {
  /// Rappresenta il giorno della settimana come un intero che parte da 0 (lunedì) e può essere
  /// grande arbitrariamente.
  _zeroBasedWeekday: number;

  /// Rappresenta il giorno della settimana del timetable di cui questo turno fa parte.
  /// Il giorno è rappresentato allo stesso modo di [_zeroBasedWeekday].
  /// In genere è uguale a [_zeroBasedWeekday], ma può letiare nel caso di timeslots che vanno oltre la mezzanotte.
  _zeroBasedWeekdayOfTimetable: number;

  hour: number;
  minute: number;

  constructor(isWeekdayZeroBased: boolean, weekday: number, hour: number, minute: number, weekdayOfTimetable: number | null = null) {
    this._zeroBasedWeekday = isWeekdayZeroBased ? weekday : (weekday - 1);
    weekdayOfTimetable = weekdayOfTimetable ?? weekday;
    this._zeroBasedWeekdayOfTimetable = isWeekdayZeroBased ? weekdayOfTimetable : (weekdayOfTimetable - 1);
    this.hour = hour;
    this.minute = minute;
  }

  equals(other: WeekdayTime): boolean {
    return other && other._zeroBasedWeekday === this._zeroBasedWeekday && other.hour === this.hour && other.minute === this.minute;
  }

  compareTo(other: WeekdayTime, compareZeroBasedWeekday = false): number {
    if (compareZeroBasedWeekday) {
      if (this._zeroBasedWeekday > other._zeroBasedWeekday) return 1;
      if (this._zeroBasedWeekday < other._zeroBasedWeekday) return -1;
    } else {
      if (this.weekday > other.weekday) return 1;
      if (this.weekday < other.weekday) return -1;
    }

    if (this.hour > other.hour) return 1;
    if (this.hour < other.hour) return -1;
    if (this.minute > other.minute) return 1;
    if (this.minute < other.minute) return -1;
    return 0;
  }

  /// Restituisce un nuovo [WeekdayTime] con aggiunte le ore e i minuti passati come parametro.
  add(hours: number, minutes: number): WeekdayTime {
    let dateTime = moment({ hour: this.hour, minute: this.minute });
    let newDateTime = moment(dateTime).add(hours, "hour").add(minutes, "minute");

    let newZeroBasedWeekday = this._zeroBasedWeekday + newDateTime.isoWeekday() - dateTime.isoWeekday();
    return new WeekdayTime(true, newZeroBasedWeekday, newDateTime.hour(), newDateTime.minute(), this._zeroBasedWeekdayOfTimetable);
  }

  differenceInMinutes(other: WeekdayTime): number {
    return (this._zeroBasedWeekday - other._zeroBasedWeekday) * 24 * 60 + (this.hour - other.hour) * 60 + this.minute - other.minute;
  }

  get weekday() { return this._zeroBasedWeekday % 7 + 1; }
  get weekdayOfTimetable() { return this._zeroBasedWeekdayOfTimetable % 7 + 1; }
  get weekdayOffset() { return this._zeroBasedWeekday - this._zeroBasedWeekdayOfTimetable; }
}

function calcOpenedShifts(supplier: Supplier): WeekdayTime[] {
  let openedShifts: WeekdayTime[] = [];
  for (let i = 1; i <= 7; i++) {
    let timetable = supplier.weekdayTimetable[i as WeekdayNumber];
    if (timetable.open) {
      for (let timeslot of timetable.timeslots) {
        const timeslotStart = moment(timeslot.start, "HH:mm");
        const timeslotEnd = moment(timeslot.end, "HH:mm");
        let start = new WeekdayTime(false, i, timeslotStart.hour(), timeslotStart.minute());
        let end = new WeekdayTime(
          true,
          timeslotStart.isBefore(timeslotEnd) ? (i - 1) : i,
          timeslotEnd.hour(),
          timeslotEnd.minute(),
          i - 1,
        );

        for (let current = start; current.compareTo(end, true) < 0; current = current.add(0, supplier.shiftLength ?? 15)) {
          openedShifts = openedShifts.filter((s) => !s.equals(current));
          openedShifts.push(current);
        }
      }
    }
  }

  openedShifts.sort((s1, s2) => s1.compareTo(s2, true));

  return openedShifts;
}

// Restituisce gli inizi dei turni disponibili dal giorno "start" al giorno "end" del fornitore
export function getShiftStartTimes(supplier: Supplier, start: Moment, end: Moment | null = null): Moment[] {
  let startTimes: Moment[] = [];
  start = moment(start).startOf("day");
  end = moment(end ?? start).startOf("day");

  let openedShifts = calcOpenedShifts(supplier);

  for (let current = start; current.isBefore(end); current.add(supplier.shiftLength ?? 15, "minutes")) {
    if (openedShifts.find((s) => s.weekday === current.isoWeekday() && s.hour === current.hour() && s.minute === current.minute()) !== undefined && !isHoliday(supplier, current))
      startTimes.push(moment(current));
  }

  return startTimes;
}

export function isHoliday(supplier: Supplier, datetime: Moment | null = null): boolean {
  if (datetime === null) datetime = moment();
  for (let h of supplier.holidays) {
    let start = moment(h.start, "DD-MM");
    let end = moment(h.end, "DD-MM");
    let startTime = moment(h.startTime ?? "00:00", "HH:mm");
    let endTime = moment(h.endTime ?? "00:00", "HH:mm");

    var isAfterStart = (start.month() < datetime.month() ||
      (start.month() === datetime.month() &&
        (start.day() < datetime.day() || (start.day() === datetime.day() &&
          (startTime.hour() < datetime.hour() || (startTime.hour() === datetime.hour() && startTime.minute() <= datetime.minute()))))));

    var isBeforeEnd = (end.month() > datetime.month() ||
      (end.month() === datetime.month() &&
        (end.day() > datetime.day() || (end.day() === datetime.day() &&
          (endTime.hour() > datetime.hour() || (endTime.hour() === datetime.hour() && endTime.minute() > datetime.minute()))))));

    var isStartAfterEnd = (start.isAfter(end) || (start.isSame(end) && startTime.isAfter(endTime)));

    if (isAfterStart && isBeforeEnd) return true;

    if (isAfterStart && isStartAfterEnd) return true;

    if (isBeforeEnd && isStartAfterEnd) return true;
  }
  return false;
}

export function getTimetableString(supplier: Supplier, datetime: Moment | null = null): String {
  if (datetime === null) datetime = moment();
  let timetable = supplier.weekdayTimetable[datetime.isoWeekday() as WeekdayNumber];

  let result = "";
  if (!isHoliday(supplier, datetime) && timetable.open) {
    let first = true;
    for (let timeslot of timetable.timeslots) {
      if (!first) {
        result += "\n";
      }
      result += timeslot.start + " - " + timeslot.end;
      first = false;
    }
    return result;
  } else
    return "Chiuso";
}

export default Supplier;