import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
//---------------- Components ------------------------
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//----------------- Other import -------------------
import { useForm } from 'react-hook-form';
import firebase from 'firebase/app';

const ContactPage = () => {
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [successApi, setSuccessApi] = useState(false);

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const onSendFormContactHandler = (data) => {
        setLoadingApi(true); 
        const firestore = firebase.firestore();
        firestore.collection("contacts").doc().set(data).then(response => {
            setLoadingApi(false);
            setSuccessApi(true);
        }).catch(error => {
            setLoadingApi(false);
            setErrorApi(true);
        })
    }

    const emailValidator = (value) => value.trim().match(/^.+@.+$/) || 'Email non valida';

    return (<>
        <Container fluid>
            { successApi ? <AlertGeneral setTrigger={setSuccessApi} closeAlone={false} color='success' description={"Email inviata con successo"} /> : null}
            { errorApi ? <AlertGeneral setTrigger={setErrorApi} closeAlone={false} color='danger' description={"Errore nell'invio contatti"} /> : null}            
            <Row style={{marginTop: '2rem'}}>
                <Col>
                    <Form onSubmit={handleSubmit(onSendFormContactHandler)} >
                        <Row style={{ marginTop: '2rem' }}>
                            <Col xs={12} lg={6}>
                                <TextBox ref={register({ validate: emailValidator })} placeHolder={'Inserisci la tua email'} type={'email'} name={'email'}
                                    error={errors.email ? errors.email.message : ''}
                                    labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                    offset={0} span={12} labelName={'Email'}
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci la tua email'} type={'input'} name={'subject'}
                                    error={errors.subject ? 'Oggetto richiesto' : ''}
                                    labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                    offset={0} span={12} labelName={'Oggetto'}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci messaggio'} type={'input'} textarea rows={8} name={'messages'}
                                    error={errors.messages ? 'Messaggio richiesto' : ''}
                                    labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                                    offset={0} span={12} labelName={'Messaggio'}
                                    inputStyle={{width: '100%', height: 200}}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '2.5rem', paddingLeft: 20 }}><Col>
                            {loadingApi ?
                                <LoadingButton />
                                :
                                <Button type='submit' className={'buttonGeneralStyle'} style={{ width: 200 }}>
                                    Contattaci
                                </Button>
                            }
                        </Col></Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    
    </>)
}

export default ContactPage;