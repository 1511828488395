import { CartProduct } from "./cart";
import Preference from "./preference";
import Product from "./product";

type OrderProduct = {
  id: string;
  name: string;
  price: number;
  imageUrl: string;
  quantity: number;
  notes: string;
  preferences: Preference[];
}

export function getOrderProducts(cartProducts: CartProduct[], products: Product[]): OrderProduct[] {
  let orderProducts: OrderProduct[] = [];
  for (let cartProduct of cartProducts) {
    if (cartProduct.quantity <= 0) continue;
    let product = products.find((p) => p.id === cartProduct.id);
    if (product === undefined)
      continue;

    let notesPreference = cartProduct.preferences.find((p) => p.type === "text");

    let preferences = cartProduct.preferences.filter((p) => p.type !== "text");

    orderProducts.push({
      id: cartProduct.id,
      imageUrl: product.imageUrl,
      quantity: cartProduct.quantity,
      name: product.name,
      price: product.price,
      notes: notesPreference?.name ?? "",
      preferences: preferences,
    });
  }
  return orderProducts;
}


export default OrderProduct;