import React, { FunctionComponent } from 'react';
//-------------------- Components -------------------
import TopNavbar from '../Components/Hoc/TopNavbar/TopNavbar';
import Footer from '../Components/Hoc/Footer/Footer';
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';

const trackPage = (page: string) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};

//@ts-ignore
ReactPixel.init('2891884017701368', {}, options);


const LayoutGeneral: FunctionComponent<{ page: string }> = ({ children, page }) => {
    return (<>
        {trackPage(page ? page : 'Another Page')}
        <TopNavbar />
        {children}
        <Footer />
    </>)
}


export default LayoutGeneral;