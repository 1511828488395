import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
//----------------- Other import -------------------
import Background from '../../../Assets/Images/imm-lavora.png';
import Rider from '../../../Assets/Images/imm-Rider.png';
import Fornitori from '../../../Assets/Images/imm-fornitori.png';
import classes from './Home.module.css';
import { Link } from 'react-router-dom';

const WorkWithUs = () => {
    const [sections, setSections] = useState([{ id: 1, title: 'Sono un', description: 'FORNITORE', imgUrl: Fornitori },
    { id: 2, title: 'Sono un', description: 'RIDER', imgUrl: Rider, }])

    return (<>
        <Row style={{
            backgroundImage: `url(${Background})`, opacity: '90%', backgroundRepeat: 'no-repeat', minHeight: 400,
            backgroundPosition: 'center center', backgroundSize: 'cover', marginTop: '2rem'
        }}>
            <Col className='text-center'>
                <Row style={{ marginTop: 20 }}>
                    <Col xs={12} md={12} lg={6}></Col>
                    <Col lg={6}>
                        <p className={classes.workWithUsTitle}>Lavora con noi</p>
                    </Col>
                </Row>
                <Row style={{ marginTop: '1rem' }}>
                    <Col xs={12} md={6}></Col>
                    {sections.map(section => {
                        return (
                            <Col key={section.id} className='text-center' xs={12} md={3} style={{ marginTop: 10 }}>
                                <Link to='/lavora-con-noi' >
                                    <Card className={classes.cardStyle}>
                                        <Row><Col><Image src={section.imgUrl} style={{ width: '100%', height: 160, borderTopLeftRadius: 15, borderTopRightRadius: 15 }} /></Col></Row>
                                        <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                            <Col xs={12}><span className={classes.workWithUsName}>
                                                {section.title}
                                            </span>
                                            </Col>
                                            <Col xs={12}><span className={classes.workWithUsDescription}>
                                                {section.description}
                                            </span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
            </Col>
        </Row>
    </>)
}


export default WorkWithUs;