import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//-----------Components ---------------
import TextBox from '../../Commons/FormComponent/TextBox';
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../Commons/AlertGeneral/AlertGeneral';
import SectionTitle from '../../PrivatePages/ProfilePage/SectionTitle';
//-----------Other import ---------------
import { useForm } from 'react-hook-form';
import {useSelector} from 'react-redux';
import { useFirebase, isLoaded,isEmpty } from 'react-redux-firebase';

const ChangePasswordForm = () => {
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [successApi, setSuccessApi] = useState(false);
    const firebaseApi = useFirebase();

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const onClickChangePassword = (data) => {
        setLoadingApi(true);
        firebaseApi.auth().currentUser.updatePassword(data.password).then(response => {
            setSuccessApi(true);
            setLoadingApi(false);
        }).catch(error => {
            setErrorApi(true);
            setLoadingApi(false);
        })
    }

    const passwordValidator = (value) => value.trim().match(/^[^\s]{8,20}$/) || 'Password non valida: no spazi, min 8 caratteri, massimo 20 caratteri';
    const passwordConfirmValidator = (value) => value === watch('password') || 'Le Password non corrispondono';


    return(<>
        <SectionTitle title={'Cambia Password'}/>
        <Form onSubmit={handleSubmit(onClickChangePassword)} >
            <Row className='rowMarginForm'>
                <Col xs={12} lg={12} className='colMarginForm'>
                    <TextBox ref={register({ validate: passwordValidator })} placeHolder={'Inserisci la tua password'} type={'password'} name={'password'}
                        error={ errors.password ?  errors.password.message : ''} offset={2} span={8} labelName={'Password'}
                        labelStyle={{textTransform: 'capitalize' ,fontWeight: 'bolder'}}
                    />
                </Col>
                <Col xs={12} lg={12} className='colMarginForm'>
                    <TextBox ref={register({ validate: passwordConfirmValidator })} placeHolder={'Conferma la tua password'} type={'password'} name={'confirm_password'}
                        error={ errors.confirm_password ?  errors.confirm_password.message : ''} offset={2} span={8} labelName={'Conferma Password'}
                        labelStyle={{textTransform: 'capitalize' ,fontWeight: 'bolder'}}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: '2.5rem', paddingLeft: 20}}><Col>
                {loadingApi ? 
                    <LoadingButton /> 
                : 
                    <Button type='submit' className={'buttonGeneralStyle'} style={{width: 200}}>
                        Modifica
                    </Button>
                }
            </Col></Row>
        </Form>
    </>)
}


export default ChangePasswordForm;