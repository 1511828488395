import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
//-------------------- Components --------
import AddressBar from './AddressBar';
//-------- Other -----
import Placeholder from '../../../Assets/Images/cover.png';
import Scooter from '../../../Assets/Images/Scooter.png';
import classes from './Home.module.css';

const Cover = ({ refButton, addressRef }) => {
    return (<>
        <Row style={{
            backgroundImage: `url(${Placeholder})`, opacity: '90%', backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center', backgroundSize: 'cover'
        }}
            className={classes.heigthCover}
        >
            <Col className='text-right'>
                <Row style={{ marginTop: 40 }}>
                    <Col lg={12}>
                        <Row><Col><span className={classes.coverTitle} ref={refButton}>I RISTORANTI</span></Col></Row>
                        <Row><Col><span className={classes.coverTitle}>A PORTATA DI APP</span></Col></Row>
                    </Col>
                </Row>
                <AddressBar addressRef={addressRef} />
            </Col>
        </Row>
        <Row>
            <Col style={{ marginTop: 10 }} xs={12} md={6} lg={6}>
                <span className={classes.smallTextStyle}>
                    Ordinare non e' mai stato cosi facile
                </span>
            </Col>
            <Col className={`text-right ${classes.scooterAlign}`} xs={12}>
                <Image src={Scooter} className={classes.scuterStyle} />
            </Col>
        </Row>
    </>)
}


export default Cover;