import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import classes from './SupplierPage.module.css';
import Button from 'react-bootstrap/Button';
import { useWindowWidth } from '@react-hook/window-size'; 

const SideMenu = ({fetchedTags, onClickTagChoosenHandler, tagsChoosen, onResetTagHandler}) => {
    const screenWidth = useWindowWidth();
    
    return(<>
        <Row>
            {screenWidth > 1025 ? 
                <>
                <Col xs={12} className='text-left' style={{marginTop: 10}}>
                    <Form.Check
                        type={'checkbox'}
                        disabled={!tagsChoosen.length}
                        checked={!tagsChoosen.length}
                        label={<span className={classes.categoryStyle}>Tutti</span>}
                        onChange={() => onResetTagHandler() }
                    />
                </Col>
                {fetchedTags.map(tag => {
                    return(
                        <Col key={tag.id} xs={12} className='text-left' style={{marginTop: 10}}>
                            <Form.Check
                                type={'checkbox'}
                                label={<span className={classes.categoryStyle}>{tag.name}</span>}
                                onChange={() => onClickTagChoosenHandler(tag.id) }
                                checked={tagsChoosen.find( tagChoosen => tagChoosen === tag.id)}
                            />
                        </Col>
                    )
                })}
                </>
            : <Col>
                    <div className={classes.scrollingWrapper}>
                        <div className={classes.cartItem}>
                            <Button 
                                style={{width: 120}}
                                onClick={() => onResetTagHandler() }
                                className={!tagsChoosen.length ? 'buttonGeneralStyle' : ''}
                                disabled={!tagsChoosen.length}>Tutti</Button>
                        </div>
                        {fetchedTags.map(tag => {
                        return(
                            <div className={classes.cartItem} key={tag.id}>
                                <Button style={{width: 120}}
                                    className={tagsChoosen.find( tagChoosen => tagChoosen === tag.id) ? 'buttonGeneralStyle' : ''}
                                    onClick={() => onClickTagChoosenHandler(tag.id) }
                                >{tag.name}</Button>
                            </div>
                            )
                        })}
                    </div>
                </Col> 
            }
        </Row>
    
    </>)
}


export default SideMenu;