import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//-----------Components ---------------
import SectionTitle from '../../PrivatePages/ProfilePage/SectionTitle';
import TextBox from '../../Commons/FormComponent/TextBox';
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../Commons/AlertGeneral/AlertGeneral';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import firebase from 'firebase/app';

const ProfileForm = () => {
    const { auth, profile } = useSelector((state) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [successApi, setSuccessApi] = useState(false);

    useEffect(() => {
        if (isLoaded(auth) && isLoaded(profile) && !isEmpty(auth) && !isEmpty(profile)) {
            reset({ email: auth.email, phoneNumber: profile.phoneNumber, nominative: profile.nominative })
        }
    }, [auth, profile])

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
        defaultValues: {
            email: '',
            nominative: '',
            phoneNumber: '',
        }
    }); // initialise the hook

    const onClickChangeProfile = (data) => {
        const firestore = firebase.firestore();
        const auth = firebase.auth();
        setLoadingApi(true);
        firestore.collection("users").doc(auth.uid).update({ nominative: data.nominative, phoneNumber: data.phoneNumber, email: data.email }).then(response => {
            auth.currentUser.updateEmail(data.email).then(response => {
                setSuccessApi(true);
                setLoadingApi(false);
            }).catch(error => {
                setErrorApi(true);
                setLoadingApi(false);
            });
        }).catch(error => {
            setErrorApi(true);
            setLoadingApi(false);
        });

    }

    const emailValidator = (value) => value.trim().match(/^.+@.+$/) || 'Email non valida';


    return (<>
        { successApi ? <AlertGeneral setTrigger={setSuccessApi} closeAlone={false} color='success' description={"Profilo modificato con successo"} /> : null}
        { errorApi ? <AlertGeneral setTrigger={setErrorApi} closeAlone={false} color='danger' description={"Errore nel modificare il profilo"} /> : null}
        <SectionTitle title={'profilo'} />
        <Form onSubmit={handleSubmit(onClickChangeProfile)} >
            <Row style={{ marginTop: '2rem' }}>
                <Col xs={12} lg={12}>
                    <TextBox ref={register({ validate: emailValidator })} placeHolder={'Inserisci la tua email'} type={'email'} name={'email'}
                        error={errors.email ? errors.email.message : ''}
                        labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                        offset={2} span={8} labelName={'Email'}
                    />
                </Col>
                <Col xs={12} lg={12} style={{ marginTop: '1rem' }}>
                    <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci il tuo nome'} type={'nominative'} name={'nominative'}
                        error={errors.nominative ? 'Campo richiesto' : ''}
                        labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                        offset={2} span={8} labelName={'Nome'}
                    />
                </Col>
                <Col xs={12} lg={12} style={{ marginTop: '1rem' }}>
                    <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci il tuo numero di telefono'} type={'phoneNumber'} name={'phoneNumber'}
                        error={errors.phoneNumber ? 'Campo Richiesto' : ''}
                        labelStyle={{ fontWeight: 'bolder', textTransform: 'capitalize' }}
                        offset={2} span={8} labelName={'Numero di telefono'}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '2.5rem', paddingLeft: 20 }}><Col>
                {loadingApi ?
                    <LoadingButton />
                    :
                    <Button type='submit' className={'buttonGeneralStyle'} style={{ width: 200 }}>
                        Modifica
                    </Button>
                }
            </Col></Row>
        </Form>
    </>)
}


export default ProfileForm;