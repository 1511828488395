import firebase from "firebase";
import moment from "moment";
import Shift from "../models/shift";
import Supplier, { getShiftStartTimes } from "../models/supplier";
import FirestoreService from "../services/firestore_service";

const MAX_ORDER_DAYS = 30;

async function getAvailableShifts(supplier: Supplier): Promise<Shift[]> {
  let now = moment();
  // Orari di inizio turno nel range di 2 giorni
  let startTimes = getShiftStartTimes(supplier, now, moment(now).add(MAX_ORDER_DAYS, "days"));

  /* 
    Non è possibile richiedere un orario di consegna entro i minuti rappresentati da supplier.minOrderTime o oltre i 30 giorni 
  */
  startTimes = startTimes.filter((startTime) => {
    let diffInMinutes = startTime.diff(now, "minutes");
    let diffInHours = startTime.diff(now, "hours");

    let endTime = (supplier.minOrderTime ?? (supplier.shiftLength ?? 15) * 3);

    return diffInMinutes >= endTime && diffInHours <= (MAX_ORDER_DAYS * 24 - 1);
  });

  if (startTimes.length === 0) return [];

  let startTime = firebase.firestore.Timestamp.fromMillis(startTimes[0].valueOf());
  let endTime = firebase.firestore.Timestamp.fromMillis(startTimes[startTimes.length - 1].valueOf());

  let db = FirestoreService.getInstance();

  let shifts: Shift[];
  if (supplier.useOwnDrivers === true) {
    shifts = startTimes.map((s) => ({
      startTime: firebase.firestore.Timestamp.fromMillis(s.valueOf()),
      endTime: firebase.firestore.Timestamp.fromMillis(moment(s).add(supplier.shiftLength ?? 15, "minutes").valueOf()),
      occupiedDrivers: [],
      availableDriversCount: 0,
      drivers: [],
      driversCount: 0,
    }));
  } else {
    shifts = await db.getShifts(supplier.areaId, startTime, endTime);

    shifts = shifts.filter((s) => s.availableDriversCount >= 1 && startTimes.find((st) => st.isSame(moment(s.startTime.toMillis())) !== undefined));
  }

  // Ottiene i turni nel range di orario specificato
  let blockedShifts = await db.getBlockedShifts(supplier.reference.id, startTime, endTime);
  let maxOrderCount = await db.getMaxOrderCount(supplier.reference.id);
  let shiftOrderCounts = await db.getShiftOrderCounts(supplier.reference.id, startTime, endTime);

  // Filtra in modo da mostrare solamente i turni corrispondenti agli orari di apertura del fornitore e quelli con fattorini disponibili
  return shifts.filter((s) => {
    let startTimeMoment = moment(s.startTime.toMillis());
    if (blockedShifts.find((bs) => bs.startTime.isEqual(s.startTime)) !== undefined) return false;
    let weekdayMaxOrderCount = maxOrderCount.find((e) => e.weekday === startTimeMoment.isoWeekday());
    if (weekdayMaxOrderCount !== undefined) {
      let shiftOrderCountModel = shiftOrderCounts.find((e) => e.startTime.isEqual(s.startTime));
      let orderCount = shiftOrderCountModel?.count ?? 0;
      let maxOrders = weekdayMaxOrderCount.maxOrders[startTimeMoment.format("HH:mm")];
      if (maxOrders !== -1 && orderCount >= maxOrders) return false;
    }
    return true;
  });
}

export default getAvailableShifts;