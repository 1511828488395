import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image';
//-------------- Compoents ----------------------
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
//------------- Others ------------------------
import { useParams, Link } from 'react-router-dom';
import classes from './EmailConfirmation.module.css';
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";




const EmailConfirmation = () => {
    const { token } = useParams<{ token: string }>();
    const [loadingApi, setLoadingApi] = useState(false);
    const [successApi, setSuccessApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    useEffect(() => {
        window.scroll(0, 0);
        setSuccessApi(true);
        setErrorApi(true);
        //setLoadingApi(true);
        /*clientCredentialApi.confirmEmail({email_token: token}).then(response => {
            setLoadingApi(false)
            setSuccessApi(true);
        }).catch(error => {
            setLoadingApi(false)
            setErrorApi(true);
        })*/
    }, [])

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Conferma Email</title>
            <meta name="description" content='Conferma Email' />
        </Helmet>
        <Container fluid>
            <Row style={{ marginTop: 60 }}><Col>
                {loadingApi ? <LoadingButton /> :
                    successApi ?
                        <span style={{ fontSize: 30, fontWeight: 'bold', color: 'green' }}>Email verificata con successo</span>
                        : null}
                {errorApi ?
                    <span style={{ fontSize: 30, fontWeight: 'bold' }} className='text-danger'>Errore nel verficatre la mail</span>
                    : null}
            </Col></Row>
            {successApi ?
                <Row style={{ marginTop: '2rem' }}>
                    <Col>
                        <Link to='/accedi'>
                            <Button className={'buttonGeneralStyle'} style={{ width: 200 }}>
                                Continua
                            </Button>
                        </Link>
                    </Col>
                </Row>
                : null}
        </Container>
    </>)
}


export default EmailConfirmation