// Lo stato MODIFIED non viene utilizzato in quanto non è possibile modificare un pagamento una volta eseguito.

enum OrderStates {
  NEW = "NEW",
  ACCEPTED = "ACCEPTED",
  //MODIFIED = "MODIFIED", 
  CANCELLED = "CANCELLED",
  READY = "READY",
  PICKED_UP = "PICKED_UP",
  DELIVERED = "DELIVERED",
  REFUSED = "REFUSED",
}

export default OrderStates;