import * as actionsTypes from './actionTypes';

export const populateSuppliers = (suppliers) => {
    return{
        type: actionsTypes.POPULATE_SUPPLIER,
        suppliers: suppliers,
        supplierMissing: suppliers.length ? false : true
    }
}

export const cleanSupplierMissing = () => {
    return{
        type: actionsTypes.CLEAN_SUPPLIER_MISSING,
    }
}